/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import { renderValue } from 'utils/functions';

interface InputRangeProps {
  label?: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  labelValue?: string;
  onChange?: (val: number) => void;
}

const InputRange: React.FC<InputRangeProps> = ({
  label,
  value,
  labelValue,
  minValue,
  maxValue,
  onChange,
}) => (
  <div className="a-inputRange">
    <div className="a-inputRange_head">
      <label>
        {label}
      </label>
      <label>
        {labelValue}
      </label>
    </div>
    <input
      className="a-inputRange_input"
      min={minValue}
      max={maxValue}
      value={value}
      type="range"
      onChange={(e) => onChange && onChange(+e.target.value)}
    />
  </div>
);

InputRange.defaultProps = {
};

export default InputRange;

interface MultiInputRangeProps {
  minValue: number,
  maxValue: number,
  onChange: (val: { min: string | number; max: string | number; }) => void;
  defaultValue?: {
    min: number;
    max: number;
  };
  prefixLabel?: string;
  isPrice?: boolean;
}

export const MultiInputRange: React.FC<MultiInputRangeProps> = ({
  minValue,
  maxValue,
  onChange,
  defaultValue,
  prefixLabel,
  isPrice,
}) => {
  const [minValueChange, setMinValueChange] = useState(0);
  const [maxValueChange, setMaxValueChange] = useState(0);
  const rangeRef = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState(0);

  const getPercent = useCallback(
    (value: number) => ((value - minValue) / (maxValue - minValue)) * 100,
    [minValue, maxValue]
  );

  useEffect(() => {
    if (rangeRef.current) {
      const minPercent = getPercent(minValueChange);
      const maxPercent = getPercent(maxValueChange);
      rangeRef.current.style.left = `${minPercent}%`;
      rangeRef.current.style.width = `${maxPercent - minPercent}%`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxValueChange, minValueChange, defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      setMaxValueChange(defaultValue.max);
      setMinValueChange(defaultValue.min || minValue);
    }
  }, [defaultValue, maxValue, minValue]);

  return (
    <div className="a-multiInputRange">
      <input
        type="range"
        min={minValue}
        max={maxValue}
        value={minValueChange}
        style={{ zIndex: focus === 1 ? 4 : 3 }}
        onFocus={() => setFocus(1)}
        className="a-multiInputRange_inputMin"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(+event.target.value, maxValueChange);
          setMinValueChange(value);
          onChange({ min: value, max: maxValueChange });
        }}
      />
      <input
        type="range"
        min={minValue}
        max={maxValue}
        value={maxValueChange}
        style={{ zIndex: focus === 2 ? 4 : 3 }}
        onFocus={() => setFocus(2)}
        className="a-multiInputRange_inputMax"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(+event.target.value, minValueChange);
          setMaxValueChange(value);
          onChange({ min: minValueChange, max: value });
        }}
      />
      <div className="a-multiInputRange_slider">
        <div className="a-multiInputRange_slider-track" />
        <div ref={rangeRef} className="a-multiInputRange_slider-range" />
      </div>
      <div className="a-multiInputRange_head">
        <label>
          {renderValue(minValueChange, isPrice, prefixLabel)}
        </label>
        <label>
          {renderValue(maxValueChange, isPrice, prefixLabel)}
        </label>
      </div>
    </div>
  );
};
