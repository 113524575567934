import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import PopupProduct from '../PopupAddToCart';
import PopupCombo from '../PopupCombo';

import Footer from 'components/templates/Footer';
import Header from 'components/templates/Header';

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const state = location.state as { redirect?: boolean };

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <>
      {state && state.redirect && (
        <Helmet>
          <meta name="render:status_code" content="301" />
          <meta name="render:header" content={`Location: ${window.location.toString()}`} />
        </Helmet>
      )}
      <Helmet>
        <meta property="product:category" content="7313" />
        <link rel="alternate" type="application/rss+xml" title="Gumac.vn Mẹo hay RSS »" href="https://cms.gumac.vn/rss/meo-hay-1/feed" />
        <link rel="alternate" type="application/rss+xml" title="Gumac.vn Magazine RSS »" href="https://cms.gumac.vn/rss/magazine/feed" />
      </Helmet>
      <div className="c-layout">
        <Header />

        {/* Minus height header desktop up */}
        <main>
          {children}
        </main>

        <LazyLoad height={1000} offset={1000}>
          <Footer hideSubscribeToNotifications={false} />
        </LazyLoad>
        {/* Popup Add Product to cart */}
        <PopupProduct />
        <PopupCombo />
      </div>
    </>
  );
};

export default Layout;
