/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';

export type IconName =
  | 'exchange'
  | 'free_delivery'
  | 'vip'
  | 'love_white'
  | 'love_red'
  | 'send_white'
  | 'flash_sale'
  | 'flash_sale_2'
  | 'add_cart'
  | 'heart_white'
  | 'close_black'
  | 'close_white'
  | 'chevron'
  | 'search_black'
  | 'search_white'
  | 'hamburger_white'
  | 'hamburger_black'
  | 'location_search_white'
  | 'location_white'
  | 'user_black'
  | 'heart_black'
  | 'cart_black'
  | 'phone_white'
  | 'cart_gray'
  | 'cart_light_blue'
  | 'discount_gray'
  | 'discount_light_blue'
  | 'home_gray'
  | 'home_light_blue'
  | 'menu_gray'
  | 'menu_light_blue'
  | 'user_gray'
  | 'user_light_blue'
  | 'arrow_blue'
  | 'heart_blue'
  | 'arrow_radius'
  | 'category'
  | 'price'
  | 'size'
  | 'color'
  | 'collection'
  | 'star_out_line'
  | 'star_blue'
  | 'star_orange'
  | 'upload'
  | 'filter_blue'
  | 'arrow_down_gray'
  | 'clock_pink'
  | 'eye_pink'
  | 'user_account_blue'
  | 'close_gray'
  | 'plus_gray'
  | 'subtract_gray'
  | 'add_cart_circle_blue'
  | 'user'
  | 'box'
  | 'wish_list'
  | 'research'
  | 'history'
  | 'voucher'
  | 'location'
  | 'address_blue'
  | 'comment'
  | 'remark_comment'
  | 'marker_yellow'
  | 'phone_blue'
  | 'clock_blue'
  | 'info'
  | 'undo'
  | 'facebook_blue'
  | 'google'
  | 'circle_checked_green'
  | 'triangle_warning_yellow'
  | 'circle_error_red'
  | 'arrow_prev_gray'
  | 'clock_white'
  | 'email_white'
  | 'fanpage_white'
  | 'messenger_white'
  | 'website_white'
  | 'button_add'
  | 'edit'
  | 'add'
  | 'tick'
  | 'pencil'
  | 'close_circle'
  | 'phone_light_blue'
  | 'clock_light_blue'
  | 'fire_work'
  | 'loading'
  | 'caret_back'
  | 'caret_forward'
  | 'logout'
  | 'image'
  | 'check_success'
  | 'salary'
  | 'time_blue'
  | 'right_arrow'
  | 'file_blue'
  | 'phone_fill_blue'
  | 'calendar_days';

export type IconSize =
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '16'
  | '18'
  | '20'
  | '21'
  | '24'
  | '25'
  | '27'
  | '28'
  | '26'
  | '30'
  | '32'
  | '34'
  | '35'
  | '37'
  | '46'
  | '52'
  | '69';
interface IconProps {
  iconName?: IconName;
  iconSize?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconSize, iconName }) => (
  <div className="a-icon" style={{ width: `${iconSize}px`, height: `${iconSize}px` }}>
    <img width="20" height="20" src={require(`assets/icons/ic_${iconName}.svg`)} alt={iconName} loading="lazy" />
  </div>
);

Icon.defaultProps = {
  iconName: undefined,
  iconSize: undefined,
};

export default Icon;
