import { createAsyncThunk } from '@reduxjs/toolkit';

import getMenusService from 'services/menus';
import { IMenu } from 'services/menus/types';

const getMenusAsync = createAsyncThunk<
  IMenu[],
  void,
  { rejectValue: unknown }
>(
  'menus/getMenus',
  async (_, { rejectWithValue }) => {
    try {
      return await getMenusService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default getMenusAsync;
