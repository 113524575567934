/* eslint-disable no-param-reassign */
const useDebounceCallback = <F extends (...args: any) => void>(
  func: F,
  waitFor: number,
  timeoutId: React.MutableRefObject<NodeJS.Timeout | undefined>,
) => {
  const debounced = (...args: any) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export default useDebounceCallback;
