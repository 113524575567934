import { CheckVoucherPayload, ICheckVoucher, IVoucher } from './types';

import axiosInstance from 'services/common/instance';

export const postCheckVoucherService = async (
  code: string,
  params: CheckVoucherPayload
): Promise<ICheckVoucher> => {
  const res = await axiosInstance.post(`vouchers/check/${code}`, params);
  return res.data.data;
};

export const getListVoucherCustomerService = async (): Promise<IVoucher[]> => {
  const res = await axiosInstance.get('voucher/get-list-voucher-of-customer');
  return res.data.data;
};

export const getListVoucherPublicService = async (): Promise<IVoucher[]> => {
  const res = await axiosInstance.get('voucher/get-publich-voucher');
  return res.data.data;
};
