import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getListShippingAddressAsync } from './thunk';
import State from './types';

import { IShippingAddress } from 'services/shippingAddress/types';
import { IVoucher } from 'services/voucher/types';

const builderCustomers = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getListShippingAddressAsync.fulfilled, ($state, action) => {
    $state.shippingAddress = action.payload;
  });
};

export const reducers = {
  setListVoucherCustomers($state: State, action: PayloadAction<IVoucher[]>) {
    $state.listVouchers = action.payload;
  },
  addShippingAddressAction($state: State, action: PayloadAction<IShippingAddress>) {
    if (action.payload.isDefault) {
      $state.shippingAddress = $state.shippingAddress.map((x) => ({ ...x, isDefault: false }));
    }
    $state.shippingAddress.push(action.payload);
  },
  updateShippingAddressAction($state: State, action: PayloadAction<IShippingAddress>) {
    $state.shippingAddress = $state.shippingAddress.map((x) => {
      if (x.shippingAddressId === action.payload.shippingAddressId) {
        return action.payload;
      }
      return ({
        ...x,
        isDefault: action.payload.isDefault ? false : x.isDefault
      });
    });
  },
  replaceShippingAddressAction($state: State, action: PayloadAction<IShippingAddress[]>) {
    $state.shippingAddress = action.payload;
  },
  cleanCustomerReducerAction($state: State) {
    $state.shippingAddress = [];
    $state.listVouchers = [];
  },
};

export default builderCustomers;
