/* eslint-disable consistent-return */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import Product from 'common/Product';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Paragraph from 'components/atoms/Paragraph';
import { toastSingleMode } from 'components/atoms/Toastify';
import Popup from 'components/organisms/Popup';
import { addProductToCart } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { togglePopupProduct } from 'store/product';
import { parseString } from 'utils/functions';

const PopupCombo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpenPopupAddCart, products } = useAppSelector((state) => state.product);

  const [colorsChecked, setColorsChecked] = useState<IColor[]>([]);

  const [sizesChecked, setSizesChecked] = useState<ISize[]>([]);

  const onChangeSize = useCallback((val: ISize, i: number) => {
    setSizesChecked((prev) => {
      const slice = [...prev];
      slice[i] = val;
      return slice;
    });
  }, []);

  const onChangeColor = useCallback((val: IColor, i: number) => {
    setColorsChecked((prev) => {
      const slice = [...prev];
      slice[i] = val;
      return slice;
    });
  }, []);

  const convert = useMemo(() => {
    if (!products?.length) return;
    return products?.map((e, i) => {
      const item = e?.sku?.find((s) => s?.color?.code === colorsChecked[i]?.code
        && s?.size?.code === sizesChecked[i]?.code);
      return item as ISku;
    });
  }, [colorsChecked, products, sizesChecked]);

  const unValidated = useMemo(
    () => convert?.some((x) => x?.salePrice === undefined || x?.salePrice === null),
    [convert]
  );

  const onSubmit = useCallback(() => {
    const isValid = convert?.length || convert?.every((e) => !!e);

    if (isValid) {
      const item = convert?.map((e, i) => ({
        sku: e,
        quantity: 1,
        name: parseString(products?.[i]?.name),
        isChecked: true,
        code: parseString(products?.[i]?.code),
      }));

      dispatch(addProductToCart({
        listProduct: item || [],
      }));
      toastSingleMode({
        type: 'success',
        message: 'Thêm sản phẩm thành công'
      });
      dispatch(togglePopupProduct({
        isOpenPopupAddCart: undefined,
      }));
    }
  }, [convert, dispatch, products]);

  const onClosePopup = useCallback(() => {
    dispatch(togglePopupProduct({ isOpenPopupAddCart: undefined }));
  }, [dispatch]);

  useEffect(() => {
    if (isOpenPopupAddCart === 'combo') {
      setColorsChecked(products?.map((e) => e?.colors?.[0]) || []);
      setSizesChecked(products?.map((e) => e?.sizes?.[0]) || []);
    } else {
      setColorsChecked([]);
      setSizesChecked([]);
    }
  }, [isOpenPopupAddCart, products]);

  return (
    <Popup
      isOpen={isOpenPopupAddCart === 'combo'}
      sizeClose="24"
      iconName="close_circle"
      handleClose={onClosePopup}
      variant="combo"
    >
      <div className="t-popupCombo">
        <div className="t-popupCombo_list">
          {products?.map((e, i) => (
            <React.Fragment key={`combo-product-${i.toString()}`}>
              {i !== 0 && (
                <div className="t-popupCombo_plus">
                  <Icon iconName="close_black" iconSize="16" />
                </div>
              )}
              <div className="t-popupCombo_item">
                <Product
                  {...e}
                  combo={{
                    colorChecked: colorsChecked[i]?.code,
                    sizeChecked: sizesChecked[i]?.code,
                    onChangeSize: (val) => onChangeSize(val, i),
                    onChangeColor: (val) => onChangeColor(val, i)
                  }}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="t-popupCombo_buy">
          <Button disabled={unValidated} onClick={onSubmit} modifiers={['dark', 'sm']}>
            <Paragraph.Text modifiers={['white', 's01']} size={{ fontSize: '16', lineHeight: '19' }}>
              MUA NGAY
            </Paragraph.Text>
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default PopupCombo;
