import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useAppSelector } from 'store/hooks';

const useTagManager = () => {
  const gtmId = useAppSelector((state) => state.systems.systems?.gtmId?.content);

  useEffect(() => {
    if (!gtmId) return;
    setTimeout(() => {
      TagManager.initialize({
        gtmId,
      });
    }, 5000);
  }, [gtmId]);
};

export default useTagManager;
