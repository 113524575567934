/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';

import Image from 'components/atoms/Image';
import Carousel, { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import Player from 'components/organisms/Player';
import { convertSrcVideo, youtubeParser } from 'utils/functions';
import { trackingClickViewImageOnPopupEvent } from 'utils/trackingGA';

export interface SliderImagesProps {
  list?: {
    code?: string;
    src: string;
    alt?: string;
    video?: string;
    currentSkuCode?: string;
  }[];
  customThumbnailSettings?: Settings;
  productName?: string;
  productId?: number;
  category?: string;
}

const SliderImages: React.FC<SliderImagesProps> = ({
  list,
  customThumbnailSettings,
  productId,
  productName,
  category,
}) => {
  const [nav1, setNav1] = useState<Slider | null>();
  const [nav2, setNav2] = useState<Slider | null>();

  const [indexActive, setIndexActive] = useState<number>();

  const mainSettings: Settings = {
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    infinite: false,
    afterChange: (index) => {
      setIndexActive(index);
    }
  };

  const thumbnailSettings: Settings = {
    dots: false,
    arrows: false,
    infinite: list && list?.length > 7,
    slidesToScroll: 1,
    slidesToShow: 7,
    focusOnSelect: true,
    vertical: true,
    prevArrow: <PrevArrow customArrow="transparentBlue" />,
    nextArrow: <NextArrow customArrow="transparentBlue" />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          infinite: list && list?.length > 6,
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          infinite: list && list?.length > 5,
          slidesToShow: 5,
          slidesToScroll: 1,
          vertical: false,
          arrows: true
        },
      },
      {
        breakpoint: 920,
        settings: {
          infinite: list && list?.length > 7,
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: list && list?.length > 5,
          slidesToShow: 5,
          slidesToScroll: 1,
          vertical: false,
          arrows: true
        },
      },
    ],
  };

  return (
    <div className="t-productDetailSlider">
      {list && list?.length > 0 && (
        <>
          <div className="t-productDetailSlider_left">
            <div className="t-productDetailSlider_thumbnail">
              <Carousel
                asNavFor={nav1 as Slider}
                ref={(slider) => setNav2(slider)}
                settings={customThumbnailSettings || thumbnailSettings}
              >
                {list?.map((item, index) => (
                  <div
                    className={`t-productDetailSlider_thumbnail_item ${item.video ? 'video' : ''}`}
                    key={`thumbnail-${index.toString()}`}
                  >
                    <Image ratio="95x128" src={item.src} alt={item.alt} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="t-productDetailSlider_right">
            <div className="t-productDetailSlider_main">
              <Carousel
                asNavFor={nav2 as Slider}
                ref={(slider) => setNav1(slider)}
                settings={mainSettings}
              >
                {list?.map((item, index) => (item.video ? (
                  <div
                    key={`main-${index.toString()}`}
                    className="t-productDetailSlider_main_item video"
                  >
                    {
                      indexActive === index ? (
                        <Player
                          thumbnailSrc={item.src || ''}
                          isYoutube={!!youtubeParser(item.video)}
                          videoSrc={convertSrcVideo(item.video)}
                          thumbnailRatio="548x868"
                        />
                      ) : <Image ratio="548x868" src={item.src} alt={item.alt} />
                    }
                  </div>
                ) : (
                  <div key={`main-${index.toString()}`}>
                    <a
                      href={item.src}
                      data-pswp-width="1000"
                      data-pswp-height="1406"
                      target="_blank"
                      rel="noreferrer"
                      title={item.alt}
                      onClick={() => trackingClickViewImageOnPopupEvent({
                        link_url: item.src,
                        item_code: item.code ?? '',
                        item_name: productName ?? '',
                        item_id: productId ? String(productId) : '',
                        item_category: category,
                        item_sku_code: item.currentSkuCode
                      })}
                    >
                      <div style={{ position: 'relative' }}>
                        <Image ratio="548x868" src={item.src} alt={item.alt} />
                      </div>
                    </a>
                  </div>
                )))}
              </Carousel>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SliderImages;
