import { createSlice } from '@reduxjs/toolkit';

import builderSystem from './action';
import State from './types';

const initialState: State = {
  contactInformation: undefined,
  hotlineSites: undefined,
  footer: undefined,
  sectionBanner: undefined,
  social: undefined,
  mediaUrl: undefined,
  systems: undefined,
  faceBookID: undefined,
  static: undefined,
};

const systemsSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builderSystem(builder);
  },
});

export default systemsSlice.reducer;
