import { IMenuItem } from 'services/menus/types';
// Recursive Menu
const groupMenus = (menus?: IMenuItem[]) => {
  if (!menus) return [];
  const recursiveMenus = (
    menuList: IMenuItem[],
    parentId: string,
  ): IMenuItem[] => {
    const menusGrouped: IMenuItem[] = [];
    menuList.forEach((menu) => {
      if (menu.parentId === parentId) {
        const subMenus = recursiveMenus(menuList, menu.id.toString());
        menusGrouped.push(
          subMenus.length > 0
            ? {
              ...menu,
              subMenu: subMenus,
            }
            : {
              ...menu,
            },
        );
      }
    });
    return menusGrouped;
  };
  if (menus.length > 0) {
    const firstLevelParentId = menus.find((menu) => menu.depth === '1')!.parentId;
    return recursiveMenus(menus, firstLevelParentId);
  }
  return [];
};

export default groupMenus;
