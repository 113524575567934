import { createAsyncThunk } from '@reduxjs/toolkit';

import getProfileService from 'services/auth';
import { IProfile } from 'services/auth/types';

export const getProfileAsync = createAsyncThunk<
  IProfile,
  void,
  { rejectValue: unknown }
>(
  'auth/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      return await getProfileService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default getProfileAsync;
