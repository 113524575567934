import ReactPixel from 'react-facebook-pixel';

import { PaymentInfoEventTypes, PurchaseEventTypes, ViewItemProductEventTypes } from './trackingGA';

export type ContenType = 'product' | 'product_group';

export type AddToCartDataTypes = {
  content_ids: Array<string | number> | string | number,
  content_type: ContenType,
  content_name?: string,
  content_category?: string,
  currency?: string,
  value?: number,
  price?: number,
  sale_price?: number,
  item_sku_code?: string,
};

export type InitiateCheckoutDataTypes = {
  content_type: ContenType,
  content_ids: Array<string | number> | string | number,
  value?: number,
} & PaymentInfoEventTypes;

export type PurchaseDataTypes = {
  content_type: ContenType,
  content_ids: Array<string | number> | string | number,
  value?: number,
} & PurchaseEventTypes;

export type ViewItemProductDataTypes = {
  content_type: ContenType,
  content_ids: Array<string | number> | string | number,
  value?: number,
} & ViewItemProductEventTypes;

export const pixelTrackAddToCart = (dataTracking: AddToCartDataTypes) => {
  ReactPixel.track('AddToCart', dataTracking);
};

export const pixelTrackInitiateCheckout = (dataTracking: InitiateCheckoutDataTypes) => {
  ReactPixel.track('InitiateCheckout', dataTracking);
};

export const pixelTrackPurchase = (dataTracking: PurchaseDataTypes) => {
  ReactPixel.track('Purchase', dataTracking);
};

export const pixelTrackViewContent = (dataTracking: ViewItemProductDataTypes) => {
  ReactPixel.track('ViewContent', dataTracking);
};
