import { IMenuItem } from 'services/menus/types';
import { checkSlashLink } from 'utils/functions';

export const getLinkMenu = (item?: IMenuItem) => {
  let result = '';
  if (item?.type !== 'custom_link' && item?.reference?.slug) {
    result = item?.reference?.slug;
  } else if (item?.link) {
    result = item?.link;
  }
  return checkSlashLink(result);
};

export const placeholder = null;
