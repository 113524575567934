/* eslint-disable max-len */
import React, {
  useState, useRef, ReactNode, useEffect
} from 'react';

import functions from '../functions';

import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link, { LinkProps } from 'components/atoms/Link';
import Paragraph from 'components/atoms/Paragraph';
import { useAppSelector } from 'store/hooks';
import { LOGO_DESKTOP_URL } from 'utils/constants';
import mapModifiers from 'utils/functions';

export interface NavSiderProps {
  isSiderOpen?: boolean;
  handleCloseSider?: () => void;
}

export interface SiderItemProps {
  toggleNode?: ReactNode;
  children?: ReactNode;
  isLeaf?: boolean;
  isBorderOff?: boolean;
  isLastItem?: boolean;
  type: 'link' | 'text';
  linkProps?: LinkProps;
  onClick?: () => void;
}

const SiderItem: React.FC<SiderItemProps> = ({
  children, isBorderOff, toggleNode, type, linkProps, isLastItem, isLeaf, onClick
}) => {
  const [isItemOpen, setIsItemOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const panelWrapperRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const handleToggleAccordion = () => {
    if (!loading) {
      setIsItemOpen(!isItemOpen);
    }
  };

  useEffect(() => {
    const elPanelWrapper = panelWrapperRef.current;
    const elPanel = panelRef.current;
    if (elPanelWrapper) {
      if (isItemOpen) {
        if (elPanel) {
          elPanelWrapper.style.maxHeight = `${elPanel.offsetHeight}px`;
          setLoading(true);
        }
        setTimeout(() => {
          elPanelWrapper.style.maxHeight = 'unset';
          setLoading(false);
        }, 240);
      } else {
        if (elPanel) {
          elPanelWrapper.style.maxHeight = `${elPanelWrapper.offsetHeight}px`;
          setLoading(true);
        }
        setTimeout(() => {
          elPanelWrapper.style.maxHeight = '0px';
          setLoading(false);
        }, 10);
      }
    }
  }, [isItemOpen]);
  const menuItemClass = isLeaf ? ' nav-sider_menuItemLeaf' : ' nav-sider_menuItem';
  return (
    <div className={`nav-accordion${isLastItem ? '' : menuItemClass}`}>
      <div
        className={`m-accordion_header ${mapModifiers('nav-sider_menuItemToggle', isBorderOff && 'borderNon', !children && 'cursorDefault')}`}
        onClick={type !== 'link' ? handleToggleAccordion : undefined}
      >
        <Link {...linkProps} onClick={onClick}>
          {toggleNode}
        </Link>
        {children && (
          <div
            className={mapModifiers('nav-sider_menuItemToggle_iconWrapper', type === 'link' && 'grow')}
            onClick={type === 'link' ? handleToggleAccordion : undefined}
          >
            <div className="m-accordion_icon" style={{ transform: isItemOpen ? 'rotate(180deg)' : '' }}>
              {isItemOpen ? <Icon iconSize="14" iconName="subtract_gray" /> : <Icon iconSize="14" iconName="plus_gray" />}
            </div>
          </div>
        )}
      </div>
      {children && (
        <div
          className="m-accordion_body"
          ref={panelWrapperRef}
        >
          <div
            ref={panelRef}
            className="nav-sider_menuItemPanel"
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

const NavSider: React.FC<NavSiderProps> = ({ isSiderOpen, handleCloseSider }) => {
  const menuHeader = useAppSelector((state) => state.menus.menuHeader);
  const { static: links } = useAppSelector((state) => state.systems);
  const logoDesktop = LOGO_DESKTOP_URL;

  const onCloseSider = (): void => {
    if (handleCloseSider) {
      handleCloseSider();
    }
  };

  return (
    <>
      <div className={`nav-sider ${mapModifiers('nav-sider_backdrop', isSiderOpen ? 'open' : 'close')}`} onClick={onCloseSider} />
      <div className={`nav-sider ${mapModifiers('nav-sider_wrapper', !isSiderOpen && 'close')}`}>
        <div className="nav-sider_top">
          <div className="nav-sider_logo">
            <Link href="/">
              <Image src={logoDesktop} alt="logo" ratio="151x23" />
            </Link>
          </div>
          <div onClick={onCloseSider} className="nav-sider_close">
            <Icon iconName="close_gray" iconSize="13" />
          </div>
        </div>
        <div className="nav-sider_menu">
          {menuHeader?.items?.map((e, i) => (
            <SiderItem
              key={`menu-${e.id}-${i.toString()}`}
              type="link"
              linkProps={{
                href: functions.linkMenu(e),
                target: e.target,
              }}
              toggleNode={(
                <div className={e?.cssClass}>
                  <Paragraph.Text type="span">{e.title}</Paragraph.Text>
                </div>
              )}
              onClick={handleCloseSider}
            >
              {e?.subMenu?.length ? functions.convertMenuMobile(e)?.subMenu?.map((x, ix) => (
                <React.Fragment key={`menu-block-${x.id}-${ix.toString()}`}>
                  {x?.subMenu?.map((z, iz) => (
                    <SiderItem
                      key={`menu-block-child-${z.id}-${iz.toString()}`}
                      toggleNode={(
                        <Paragraph.Text type="span" modifiers={['gray']}>{z.title}</Paragraph.Text>
                      )}
                      linkProps={{
                        href: functions.linkMenu(z),
                        target: z.target,
                      }}
                      type="link"
                      isBorderOff
                      onClick={handleCloseSider}
                    >
                      {z?.subMenu?.length ? z?.subMenu?.map((s, is) => (
                        <SiderItem
                          key={`menu-block-link-${s.id}-${is.toString()}`}
                          toggleNode={(
                            <Paragraph.Text type="span" modifiers={['gray']}>{s.title}</Paragraph.Text>
                          )}
                          type="link"
                          isBorderOff
                          isLeaf
                          linkProps={{
                            href: functions.linkMenu(s),
                            target: s.target,
                          }}
                          onClick={handleCloseSider}
                        />
                      )) : null}
                    </SiderItem>
                  ))}
                </React.Fragment>
              )) : null}
            </SiderItem>
          ))}
          {/* // TODO hardcode remove later */}
          <SiderItem
            type="link"
            linkProps={{
              href: `/${links?.showroom}`
            }}
            onClick={handleCloseSider}
            toggleNode={(
              <Paragraph.Text type="span">SHOWROOMS</Paragraph.Text>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default NavSider;
