import { createSlice } from '@reduxjs/toolkit';

import builderShippingAddress, { reducers } from './action';
import State from './types';

const initialState: State = {
  shippingAddress: [],
  listVouchers: [],
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers,
  extraReducers(builder) {
    builderShippingAddress(builder);
  },
});

export const {
  setListVoucherCustomers,
  addShippingAddressAction,
  updateShippingAddressAction,
  replaceShippingAddressAction,
  cleanCustomerReducerAction,
} = customersSlice.actions;

export default customersSlice.reducer;
