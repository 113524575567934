import { IShippingAddress, ShippingAddressPayload, ShippingFeePayload } from './types';

import axiosInstance from 'services/common/instance';

export const getListShippingAddressService = async (): Promise<IShippingAddress[]> => {
  const res = await axiosInstance.get('shipping-addresses');
  return res.data.data;
};

export const postShippingAddressService = async (
  params: ShippingAddressPayload
): Promise<IShippingAddress> => {
  const res = await axiosInstance.post('shipping-addresses', params);
  return res.data.data;
};

export const putShippingAddressService = async (
  id: string,
  params: ShippingAddressPayload
): Promise<IShippingAddress> => {
  const res = await axiosInstance.put(`shipping-addresses/${id}`, params);
  return res.data.data;
};

export const deleteShippingService = async (
  id: number
): Promise<unknown> => {
  const res = await axiosInstance.delete(`shipping-addresses/${id}`);
  return res.data;
};

export const getShippingFeeService = async (
  params: ShippingFeePayload
): Promise<number> => {
  const res = await axiosInstance.get('orders/shipping-fee', { params });
  return res.data.data;
};
