import React, { HTMLProps } from 'react';

import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import Paragraph from 'components/atoms/Paragraph';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  handleSubmit?: (val: React.FormEvent<HTMLFormElement>) => void;
}
export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>((
  {
    handleSubmit,
    ...props
  },
  ref,
) => (
  <form className="header-search-input" onSubmit={handleSubmit} noValidate>
    <input ref={ref} {...props} />
    <button type="submit">
      <Icon iconName="search_black" iconSize="13" />
    </button>
  </form>
));

export interface HeaderButtonIconProps extends HTMLProps<HTMLButtonElement> {
  icon?: {
    iconName?: IconName;
    iconSize?: IconSize;
  },
  badgeNumber?: number;
  imgSrc?: string;
  imgSrcSize?: {
    width: string,
    height: string
  }
}

export const HeaderButtonIcon: React.FC<HeaderButtonIconProps> = ({
  icon,
  badgeNumber,
  imgSrc,
  imgSrcSize,
  onClick,
}) => (
  <button aria-label="Icon button" onClick={onClick} className="header-button-icon" type="button">
    {!!badgeNumber && <div className="header-badge"><Paragraph.Text size={{ fontSize: '12', lineHeight: '14' }}>{badgeNumber > 99 ? '99+' : badgeNumber}</Paragraph.Text></div>}
    {imgSrc && (
      imgSrcSize === undefined ? <img src={imgSrc} alt="" /> : <img src={imgSrc} alt="" width={imgSrcSize.width} height={imgSrcSize.height} />
    )}
    {icon && (
      <Icon
        iconName={icon.iconName}
        iconSize={icon.iconSize}
      />
    )}
  </button>
);
