import React, { useCallback, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { SearchInput } from '../HeaderElements';

import Button from 'components/atoms/Button';
import Paragraph from 'components/atoms/Paragraph';
import { useAppSelector } from 'store/hooks';
import mapModifiers from 'utils/functions';

interface NavSearchProps {
  open?: boolean;
  handleClose?: () => void;
}

const NavSearch: React.FC<NavSearchProps> = ({
  open,
  handleClose
}) => {
  const navigate = useNavigate();
  const slugPageSearch = useAppSelector((state) => state.systems?.static?.timKiem);

  const refInput = useRef<HTMLInputElement>(null);

  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSearch = useCallback((val?: string) => {
    if (val) {
      searchParams.set('keyword', val);
    } else {
      searchParams.delete('keyword');
    }
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onSubmit = useCallback(() => {
    if (!slugPageSearch) return;
    const valueInput = refInput?.current?.value;

    if (pathname.replace('/', '') === slugPageSearch) {
      onChangeSearch(valueInput);
    } else {
      navigate({
        pathname: `/${slugPageSearch}`,
        search: valueInput ? `keyword=${valueInput}` : undefined,
      });
    }

    if (handleClose) {
      handleClose();
    }
  }, [slugPageSearch, pathname, handleClose, onChangeSearch, navigate]);

  return (
    <>
      <div className={mapModifiers('nav-search_backdrop', open && 'open')} onClick={handleClose} />
      <div className={mapModifiers('nav-search', open && 'open')}>
        <div className="nav-search_search">
          <SearchInput
            ref={refInput}
            placeholder="Tìm kiếm sản phẩm"
            handleSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          />
        </div>
        <div className="nav-search_groupBtn">
          <div>
            <Button onClick={handleClose} modifiers={['dark', 'md']}>
              <Paragraph.Text modifiers={['white', 'uppercase']} size={{ fontSize: '16', lineHeight: '24' }}>
                Hủy
              </Paragraph.Text>
            </Button>
          </div>
          <div>
            <Button
              onClick={onSubmit}
              modifiers={['primary', 'md']}
            >
              <Paragraph.Text modifiers={['white', 'uppercase']} size={{ fontSize: '16', lineHeight: '24' }}>
                Tìm kiếm
              </Paragraph.Text>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavSearch;
