/* eslint-disable react/button-has-type */
import React from 'react';

import Link from '../Link';

import loadingIcon from 'assets/images/loading.gif';
import mapModifiers from 'utils/functions';

type Sizes = 'xs' | 'sm' | 'sm-small' | 'md' | 'lg';
type Variant = 'primary' | 'dark' | 'outline-primary' | 'outline-dark' | 'combo' | 'collection-detail' | 'white' | 'red';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modifiers?: (Variant | Sizes | string)[];
  link?: {
    href?: string;
    target?: string;
  }
  isSubmit?: boolean;
  loading?: boolean;
  radius?: number;
}

const Button: React.FC<ButtonProps> = ({
  modifiers,
  isSubmit,
  loading,
  children,
  link,
  ...props
}) => {
  if (link) {
    return (
      <Link
        href={link?.href}
        target={link?.target || '_self'}
        className={mapModifiers(
          'a-button',
          'link',
          modifiers,
        )}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      type={props.type || 'button'}
      className={mapModifiers(
        'a-button',
        modifiers,
        loading && 'loading',
      )}
      {...props}
      aria-label="Aria Name"
    >
      <div className="a-button_wrapper">
        {loading ? (
          <img className="a-button_loadingIcon" src={loadingIcon} alt="loading" />
        ) : children}
      </div>
    </button>
  );
};

export default Button;
