import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

import * as functions from './functions';

import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Paragraph from 'components/atoms/Paragraph';
import { toastSingleMode } from 'components/atoms/Toastify';
import Container from 'components/organisms/Container';
import { useAsync } from 'hooks/useAsync';
import postEmailRegister from 'services/email';
import { ISocial } from 'services/systems/types';
import { useAppSelector } from 'store/hooks';
import mapModifiers, { baseURL } from 'utils/functions';
import PATHS from 'utils/paths';
import { schemasEmailRegister } from 'utils/schemas';

export interface DataRegister {
  email: string;
}

interface FooterProps {
  hideSubscribeToNotifications: boolean;
}

const SocialList: React.FC<Pick<ISocial, 'iconSocial'>> = ({ iconSocial }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {iconSocial?.map((item, itemIdx) => (
      <Link
        key={`social-${itemIdx.toString()}`}
        useExternal
        href={item.link || '#'}
        target={item.target}
        className="t-footer_socialLink"
      >
        <Image src={baseURL(item.icon?.path)} ratio="1x1" alt="social" sizeRatio={{ width: '36', height: '36' }} />
      </Link>
    ))}
  </>
);

const Footer: React.FC<FooterProps> = (
  { hideSubscribeToNotifications }
) => {
  const systems = useAppSelector((state) => state.systems);
  const { menuFooter1, menuFooter2 } = useAppSelector((state) => state.menus);
  const {
    register, handleSubmit, formState: { errors }, reset
  } = useForm<DataRegister>({
    resolver: yupResolver(schemasEmailRegister),
  });
  const [executeEmailRegister, emailRegister] = useAsync(postEmailRegister, {
    onSuccess: (res) => {
      reset();
      toastSingleMode({
        type: 'success',
        message: res?.message || 'Đăng ký nhận thông báo thành công !!'
      });
    },
    onFailed: () => {
      toastSingleMode({
        type: 'error',
        message: 'Lỗi hệ thống'
      });
    }
  });
  const submitRegister = (data: DataRegister): void => {
    executeEmailRegister(data);
  };

  return (
    <footer className="t-footer">
      <Container>
        <div className="row">
          <div className="col-12 col-xl-3">
            <div className="row">
              <div className="col-12 col-md-6 col-xl-12">
                <div className="t-footer_logo">
                  <Link href={PATHS.HOME}>
                    <img width="158" height="101" alt="gumac_logo" src={baseURL(systems?.footer?.footerLogo.path)} />
                  </Link>
                </div>
                <div className="tablet u-mt-18">
                  <div className="t-footer_social">
                    <SocialList iconSocial={systems?.social?.iconSocial || []} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-12">
                <div className="t-footer_description">
                  <Paragraph.Text
                    type="div"
                    content={systems?.footer?.shortIntroduction}
                    modifiers={['raisinBlack075']}
                    size={{ fontSize: '16', lineHeight: '21' }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-12">
                <div className="desktop mobile">
                  <div className="t-footer_social">
                    <SocialList iconSocial={systems?.social?.iconSocial || []} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            {menuFooter1?.items?.map((e, i) => (
              <React.Fragment key={`quick-link-${i.toString()}`}>
                <div className="u-mt-xl-0 u-mt-md-60 u-mt-36">
                  <Link href={functions.getLinkMenu(e)} target={e?.target}>
                    <Paragraph.Text
                      size={{ fontSize: '16', lineHeight: '19' }}
                      modifiers={['uppercase', '600', 'raisinBlack075']}
                    >
                      {e?.title}
                    </Paragraph.Text>
                  </Link>
                </div>
                {e?.subMenu && e?.subMenu?.length > 0 && (
                  <div className="row">
                    {e?.subMenu?.map((_, is) => (
                      <div className="col-6" key={`quick-block-${is.toString()}`}>
                        <ul className="t-footer_partList u-mt-16 u-mt-md-28">
                          {_?.subMenu?.map((x, ix) => (
                            <li className="u-mt-md-16 u-mt-12" key={`quick-link-item-${ix.toString()}`}>
                              <Link href={functions.getLinkMenu(x)} className="t-footer_link">
                                <Paragraph.Text
                                  modifiers={['raisinBlack075']}
                                  size={{ fontSize: '16', lineHeight: '19' }}
                                >
                                  {x?.title}
                                </Paragraph.Text>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            {menuFooter2?.items?.map((e, i) => (
              <React.Fragment key={`support-link-${i.toString()}`}>
                <div className="u-mt-xl-0 u-mt-md-60 u-mt-36">
                  <Link href={functions.getLinkMenu(e)} target={e.target}>
                    <Paragraph.Text size={{ fontSize: '16', lineHeight: '19' }} modifiers={['uppercase', '600', 'raisinBlack075']}>
                      {e.title}
                    </Paragraph.Text>
                  </Link>
                </div>
                {e?.subMenu && e?.subMenu?.length > 0 && (
                  <ul className="t-footer_partList u-mt-16 u-mt-md-28">
                    {e?.subMenu?.map((item, itemIdx) => (
                      <li className="u-mt-md-16 u-mt-12" key={`support-link-${itemIdx.toString()}`}>
                        <Link href={functions.getLinkMenu(item)} target={item.target} className="t-footer_link">
                          <Paragraph.Text modifiers={['raisinBlack075']} size={{ fontSize: '16', lineHeight: '19' }}>
                            {item?.title}
                          </Paragraph.Text>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="col-12 col-xl-3">
            <div className="row">
              <div className="col-12 col-md-6 col-xl-12">
                <div className="u-mt-40 u-mt-md-60 u-mt-xl-0">
                  <Paragraph.Text size={{ fontSize: '16', lineHeight: '19' }} modifiers={['uppercase', '600', 'raisinBlack075']}>
                    CÁCH THỨC THANH TOÁN
                  </Paragraph.Text>
                </div>
                <ul className="t-footer_paymentList u-mt-26">
                  {systems?.footer?.logoPayment.map((item, itemIdx) => (
                    <li className="t-footer_paymentItem" key={`payment-${itemIdx.toString()}`}>
                      <Image src={baseURL(item.path)} size="contain" ratio="43x27" alt={`payment ${itemIdx}`} />
                    </li>
                  ))}
                </ul>
              </div>
              {
                hideSubscribeToNotifications
                && (
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="u-mt-26 u-mt-md-60">
                      <Paragraph.Text size={{ fontSize: '16', lineHeight: '19' }} modifiers={['uppercase', '600', 'raisinBlack075']}>
                        Đăng ký nhận thông báo
                      </Paragraph.Text>
                    </div>
                    <form className="t-footer_register u-mt-26" onSubmit={handleSubmit(submitRegister)}>
                      <div className={mapModifiers('t-footer_registerHandler', errors.email && 'error')}>
                        <input placeholder="Email của bạn" {...register('email')} />
                        <button type="submit" disabled={emailRegister.loading}>
                          {emailRegister.loading ? <Icon iconName="loading" iconSize="16" />
                            : (
                              <>
                                Gửi ngay
                                <Icon iconName="send_white" iconSize="16" />
                              </>
                            )}
                        </button>
                      </div>
                    </form>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className="t-footer_divider" />
        <div className="row t-footer_bottomInfo">
          <div className="col-12 col-md-auto t-footer_copyright t-footer_text">
            <Paragraph.Text size={{ fontSize: '16', lineHeight: '21' }}>
              {systems?.footer?.copyright}
            </Paragraph.Text>
          </div>
          <div className="col-12 col-md-auto">
            <div className="t-footer_boCongThuong">
              <Link useExternal href={systems?.footer?.boCongThuongLink}>
                <Image src={baseURL(systems?.footer?.boCongThuongIcon.path)} ratio="116x44" size="contain" alt="Bo cong thuong" />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
