import { useEffect, useMemo, useRef } from 'react';

import useFacebookPixel from './useFacebookPixel';
import useGaTracker from './useGaTracker';
import useTagManager from './useTagManager';

import { getAccessToken } from 'services/common/storage';
import { getProfileAsync } from 'store/auth/thunk';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getMenusAsync from 'store/menus/thunk';
import systemsGeneralAsync, { staticAllAsync } from 'store/systems/thunk';
import { FB_PIXEL_ID } from 'utils/constants';
import initScriptSystems from 'utils/scriptSystems';
import initFacebookSdk from 'utils/sdkFacebook';

const useAppInitialize = () => {
  const dispatch = useAppDispatch();
  const token = useMemo(() => getAccessToken(), []);
  const idAppFacebook = useAppSelector((state) => state.systems?.faceBookID?.content);
  const scriptSystems = useAppSelector((state) => state?.systems?.systems?.script?.content);
  const firstScript = useRef(false);

  useEffect(() => {
    dispatch(systemsGeneralAsync());
    dispatch(getMenusAsync());
    dispatch(staticAllAsync());
    if (token) {
      dispatch(getProfileAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idAppFacebook) {
      setTimeout(() => {
        initFacebookSdk(Number(idAppFacebook));
      }, 5000);
    }
  }, [idAppFacebook]);

  useEffect(() => {
    if (scriptSystems && !firstScript.current) {
      firstScript.current = true;
      initScriptSystems(scriptSystems?.scriptSchema, true);
      setTimeout(() => {
        initScriptSystems(scriptSystems?.scriptCode);
      }, 3000);
    }
  }, [scriptSystems]);

  useGaTracker();
  useTagManager();
  useFacebookPixel(FB_PIXEL_ID);
};

export default useAppInitialize;
