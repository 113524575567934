import React, { useMemo } from 'react';

import useDeviceQueries from 'hooks/useDeviceQueries';
import mapModifiers from 'utils/functions';

export type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

interface ImageProps {
  src?: string;
  srcTablet?: string;
  srcMobile?: string;
  alt?: string;
  ratio?: Ratio;
  size?: SizeImageType;
  loading?: 'lazy' | 'eager';
  sizeRatio?: {
    width: string;
    height: string;
  };
  sizeRatioTablet?: {
    width: string;
    height: string;
  };
  sizeRatioMobile?: {
    width: string;
    height: string;
  };
}

const Image: React.FC<ImageProps> = ({
  src,
  srcMobile,
  srcTablet,
  alt,
  ratio,
  size,
  loading,
  sizeRatio,
  sizeRatioMobile,
  sizeRatioTablet,
}) => {
  const { isMobile, isTablet, isDesktop } = useDeviceQueries();
  const sourceImage = useMemo(() => {
    if (isDesktop) {
      return src || srcMobile;
    }
    if (isTablet) {
      return srcTablet || srcMobile || src;
    }
    return srcMobile || src;
  }, [isTablet, isDesktop, src, srcMobile, srcTablet]);

  const sizeImage = useMemo(() => {
    if (isMobile) {
      return {
        width: sizeRatioMobile?.width || sizeRatio?.width,
        height: sizeRatioMobile?.height || sizeRatio?.height,
      };
    }
    if (isTablet) {
      return {
        width: sizeRatioTablet?.width || sizeRatio?.width,
        height: sizeRatioTablet?.height || sizeRatio?.height,
      };
    }
    return {
      width: sizeRatio?.width,
      height: sizeRatio?.height,
    };
  }, [isMobile, isTablet, sizeRatio?.height, sizeRatio?.width,
    sizeRatioMobile?.height, sizeRatioMobile?.width,
    sizeRatioTablet?.height, sizeRatioTablet?.width]);

  return (
    <div className={mapModifiers('a-image', ratio, size)}>
      <img src={sourceImage} alt={alt || 'no alt'} loading={loading} width={sizeImage.width} height={sizeImage.height} />
    </div>
  );
};

Image.defaultProps = {
  alt: '',
  srcMobile: undefined,
  srcTablet: undefined,
  size: 'cover',
  loading: 'lazy',
};

export default Image;
