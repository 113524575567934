import React from 'react';

import mapModifiers from 'utils/functions';

interface ContainerProps {
  children?: React.ReactNode;
  fullScreen?: boolean;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  isHeader?: boolean;
  noPaddingRightTablet?: boolean
  noPaddingRightTabletDesktop?: boolean
}

const Container: React.FC<ContainerProps> = ({
  fullScreen,
  noPaddingRight,
  noPaddingLeft,
  children,
  isHeader,
  noPaddingRightTablet,
  noPaddingRightTabletDesktop
}) => (
  <div
    className={`container ${mapModifiers(
      'o-container',
      fullScreen && 'fullscreen',
      noPaddingRight && 'noPaddingRight',
      noPaddingLeft && 'noPaddingLeft',
      isHeader && 'header',
      noPaddingRightTablet && 'noPaddingRightTablet',
      noPaddingRightTabletDesktop && 'noPaddingRightTabletDesktop'
    )}`}
  >
    {children}
  </div>
);

Container.defaultProps = {
  children: undefined,
};

export default Container;
