import { createSlice } from '@reduxjs/toolkit';

import builderProfile, { reducers } from './action';
import State from './types';

const initialState: State = {
  profile: undefined,
};

const authsSlice = createSlice({
  name: 'auths',
  initialState,
  reducers,
  extraReducers(builder) {
    builderProfile(builder);
  },
});

export const { setAccountInfo, removeAccount } = authsSlice.actions;

export default authsSlice.reducer;
