import React, {
  useCallback, useMemo, useState
} from 'react';

import functions from '../functions';

import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Paragraph from 'components/atoms/Paragraph';
import { IMenuItem } from 'services/menus/types';
import { useAppSelector } from 'store/hooks';
import mapModifiers, { baseURL } from 'utils/functions';

const NavTop: React.FC = () => {
  const menuHeader = useAppSelector((state) => state.menus.menuHeader);
  const [hoverIndex, setHoverIndex] = useState<number>(-1);

  const onHoverMenu = useCallback((item?: IMenuItem, idx?: number) => {
    const status = functions.hoverMenu(item);
    if (status && idx) {
      setHoverIndex(idx);
    } else {
      setHoverIndex(-1);
    }
  }, []);

  const blockMenu = useMemo(() => {
    if (hoverIndex <= -1) return undefined;
    const item = menuHeader?.items?.[hoverIndex - 1];
    if (!item) return undefined;

    return item;
  }, [menuHeader, hoverIndex]);

  return (
    <nav onMouseLeave={() => setHoverIndex(-1)} className="nav-top">
      <ul className="nav-top_list nav-top_list-desktop">
        {menuHeader?.items?.map((item, index) => (
          <li
            key={`top-nav-item-${index.toString()}`}
            onMouseEnter={() => onHoverMenu(item, index + 1)}
            className={`${item.cssClass} ${hoverIndex === (index + 1) ? 'active' : ''}`}
          >
            <Link
              href={functions.linkMenu(item)}
              target={item.target}
            >
              <Paragraph.Text type="span" modifiers={['uppercase']} size={{ fontSize: '15', lineHeight: '17' }}>
                {item.title}
              </Paragraph.Text>
            </Link>
          </li>
        ))}
      </ul>
      {/* {blockMenu && blockMenu?.length > 0 && ( */}
      <menu className={mapModifiers('nav-top_menu', blockMenu?.subMenu && blockMenu?.subMenu?.length > 0 && 'opened')}>
        {blockMenu?.subMenu?.map((b, i) => (
          <ul
            className={`nav-top_menu_element ${b.type}`}
            key={`block-menu-${b.parentId}-${b.id}-${i.toString()}`}
          >
            {b?.subMenu?.map((s, is) => (
              <li
                key={`block-menu-child-${b.parentId}-${s.id}-${is.toString()}`}
                className={`nav-top_menu_item ${b.cssClass}`}
              >
                {(() => {
                  switch (b.type) {
                    case 'block':
                      return (
                        <>
                          <Link
                            className="link"
                            href={functions.linkMenu(s)}
                          >
                            <Paragraph.Text modifiers={['500']} size={{ fontSize: '15', lineHeight: '19' }}>
                              {s.title}
                            </Paragraph.Text>
                          </Link>
                          {s?.subMenu && s?.subMenu?.length > 0 && (
                            <ul>
                              {s?.subMenu?.map((ss, iss) => (
                                <li key={`block-menu-child-normal-${ss.parentId}-${iss.toString()}`}>
                                  <Link
                                    className="link"
                                    href={functions.linkMenu(ss)}
                                  >
                                    <Paragraph.Text modifiers={['raisinBlack075']} size={{ fontSize: '15', lineHeight: '19' }}>
                                      {ss.title}
                                    </Paragraph.Text>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                      );
                    case 'block_image':
                      return (
                        <Link href={functions.linkMenu(s)}>
                          <Image src={baseURL(s.image?.path)} ratio="284x375" size="cover" />
                        </Link>
                      );

                    case 'block_menu_image':
                      return (
                        <>
                          <Paragraph.Text
                            modifiers={['500']}
                            size={{ fontSize: '15', lineHeight: '19' }}
                          >
                            {s.title}
                          </Paragraph.Text>
                          {s?.subMenu && s?.subMenu?.length > 0 && (
                            <ul>
                              {s?.subMenu?.map((ss, iss) => (
                                <li className="menu_item" key={`block-menu-child-image-${ss.parentId}-${iss.toString()}`}>
                                  <Link
                                    className="link"
                                    href={functions.linkMenu(ss)}
                                  >
                                    <Image src={ss?.image?.path ? baseURL(ss?.image?.path) : baseURL(ss?.iconImage?.path)} ratio="1x1" size="cover" />
                                    <Paragraph.Text modifiers={['raisinBlack075', 'center']} type="div" size={{ fontSize: '14', lineHeight: '21' }}>
                                      {ss.title}
                                    </Paragraph.Text>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                      );

                    default:
                      return null;
                  }
                })()}
              </li>
            ))}
          </ul>
        ))}
      </menu>
    </nav>
  );
};

export default NavTop;
