import React, { useMemo } from 'react';

import Text from '../Paragraph/Text';

import mapModifiers from 'utils/functions';

interface QuantityProps {
  quantity: number;
  handleDecreaseQuantity?: () => void;
  handleIncreaseQuantity?: () => void;
  modifiers?: any;
  disabled?: boolean;
  isError?: boolean;
}

const Quantity: React.FC<QuantityProps> = ({
  quantity = 1,
  handleDecreaseQuantity,
  handleIncreaseQuantity,
  modifiers,
  disabled,
  isError,
}) => {
  const renderQuantity = useMemo(() => {
    if (quantity === 0) {
      return 0;
    }

    if (quantity < 10) {
      return `0${quantity}`;
    }
    return quantity;
  }, [quantity]);

  return (
    <div className={mapModifiers('a-quantity', modifiers, disabled && 'disabled', isError && 'error')}>
      <div
        className="a-quantity_decrease"
        onClick={handleDecreaseQuantity}
      >
        <div className="a-quantity_horizontal" />
      </div>

      <div className="a-quantity_quantity">
        <Text
          size={{ fontSize: '17', lineHeight: '22' }}
        >
          {renderQuantity}
        </Text>

      </div>
      <div
        className="a-quantity_increase"
        onClick={() => {
          if (!isError && handleIncreaseQuantity) {
            handleIncreaseQuantity();
          }
        }}
      >
        <div className="a-quantity_horizontal" />
        <div className="a-quantity_vertical" />
      </div>
    </div>
  );
};

Quantity.defaultProps = {
  quantity: 1,
};

export default Quantity;
