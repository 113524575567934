import ReactGA4 from 'react-ga4';

import { CURRENCY_GLOBAL } from './constants';

export type ProductEventTypes = {
  item_id: string;
  item_name: string;
  item_category?: string;
  currency?: string;
  discount?: number;
  price?: number;
  sale_price?: number;
  quantity?: number;
  coupon?: string;
  item_code?: string;
  item_sku_code?: string;
};

export type ViewItemProductEventTypes = {
  index?: number;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  promotion_id?: string;
  promotion_name?: string;
} & ProductEventTypes;

export type ProductCartEventTypes = {
  currency?: string;
  value?: number;
  coupon?: string;
  items: ViewItemProductEventTypes[];
};

export type ViewImageProductOnPopupEventTypes = {
  link_url?: string;
} & Pick<ProductEventTypes, 'item_name' | 'item_category' | 'item_code' | 'item_sku_code' | 'item_id'>;

export type SelectSizeProductEventTypes = {
  size: string;
} & Pick<ProductEventTypes, 'item_name' | 'item_category' | 'item_code' | 'item_sku_code'>;

export type SelectColorProductEventTypes = {
  color: string;
} & Pick<ProductEventTypes, 'item_name' | 'item_category' | 'item_code' | 'item_sku_code'>;

export type PromotionEventTypes = {
  creative_name?: string;
  creative_slot?: string;
  location_id?: string;
  promotion_id?: string;
  promotion_name?: string;
  items: ViewItemProductEventTypes[];
};

export type PaymentInfoEventTypes = {
  payment_type?: string;
} & ProductCartEventTypes;

export type DeliveryInfoEventTypes = {
  note?: string;
  name: string;
  phone: string;
  email?: string;
  street: string;
  province: string;
  ward: string;
  district: string;
  type: string;
  companyName: string;
  id?: number;
};

export type PurchaseEventTypes = {
  transaction_id?: string;
  tax?: number;
  shipping?: number;
} & ProductCartEventTypes;

/**
 *
 * @param list
 * View of product impressions in a list
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list
 */
export function trackingViewItemListEvent(
  list: IProducts[],
  item_list_name?: string,
  item_list_id?: string,
) {
  const dataTracking = list.map((item, index): ViewItemProductEventTypes => ({
    item_id: item.id.toString(),
    item_name: item.name,
    item_category: item?.category?.name,
    index,
    currency: CURRENCY_GLOBAL
  }));

  ReactGA4.set({
    item_list_name: undefined,
    item_list_id: undefined,
    items: undefined,
  });
  ReactGA4.event('view_item_list', {
    item_list_name,
    item_list_id,
    items: dataTracking,
  });
}

/**
 *
 * @param keyword
 */
export function trackingSearchEvent(keyword: string) {
  ReactGA4.event('search', {
    search_term: keyword,
  });
}

/**
 *  @param data
 * this event is custom event
 */
export function trackingSelectSizeEvent(
  data: SelectSizeProductEventTypes,
) {
  ReactGA4.event('select_size', data);
}

/**
 *  @param data
 * this event is custom event
 */
export function trackingSelectColorEvent(
  data: SelectColorProductEventTypes,
) {
  ReactGA4.event('select_color', data);
}

/**
 *  @param data
 * this event is custom event
 */
export function trackingClickBuyNowButtonEvent(
  data: ProductEventTypes,
) {
  ReactGA4.event('click_buynow', data);
}

/**
 * Tracking View Image
 * this event is custom event
 */

export function trackingClickViewImageOnPopupEvent(data: ViewImageProductOnPopupEventTypes) {
  ReactGA4.event('view_image', data);
}

export type ViewProductOnPopupEvent = {
  item_id: string;
  item_name: string;
  item_code: string;
};

/**
 * @param data
 * this event is custom event
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_content
 */
export function trackingClickViewProductOnPopupEvent(
  data: ViewProductOnPopupEvent,
) {
  ReactGA4.event('select_to_view_product_popup', data);
}

/**
 * Add to cart Tracking
 */

export function trackingAddToCartEvent(data: ProductCartEventTypes) {
  ReactGA4.event('add_to_cart', data);
}

/**
 * View cart or update cart
 */
export function trackingViewCartEvent(data: ProductCartEventTypes) {
  ReactGA4.set({
    currency: undefined,
    value: undefined,
    coupon: undefined,
    items: undefined,
  });
  ReactGA4.event('view_cart', data);
}

/**
 * Remove product from cart
 */
export function trackingRemoveProductFromCartEvent(data: ProductCartEventTypes) {
  ReactGA4.event('remove_from_cart', data);
}

/**
 * Apply voucher
 */
export function trackingApplyCodePromotionEvent(coupon: string) {
  ReactGA4.event('apply_voucher', { coupon });
}

/**
 * Detect item was selected from a list event
 */
export function trackingViewDetailItemEvent(
  item: ViewItemProductEventTypes,
) {
  ReactGA4.set({
    item_list_id: undefined,
    item_list_name: undefined,
    items: undefined,
  });
  ReactGA4.event('select_item', {
    item_list_id: item.item_list_id,
    item_list_name: item.item_list_name,
    items: [item]
  });
}

export function trackingNavigateFromOrderNowToPaymentEvent() {
  ReactGA4.event('shoppingcart_to_checkout', {
    navigate: 'order navigation to the checkout page'
  });
}

/**
 * Add payment info
 */
export function trackingAddPaymentInfoEvent(data: PaymentInfoEventTypes) {
  ReactGA4.event('add_payment_info', data);
}

/**
 * Delivery information
 */
export function trackingDeliveryInfoEvent(data: DeliveryInfoEventTypes) {
  ReactGA4.event('delivery_info', data);
}

/**
 * purchase
 */
export function trackingPurchaseEvent(data: PurchaseEventTypes) {
  ReactGA4.event('purchase', data);
}

/**
 * Thankyou page
 */
export function trackingThankyouPageEvent() {
  ReactGA4.event('thankyou_page', {
    message: 'Thankyou page'
  });
}

/**
 * Continue shopping
 */
export function trackingClickContinueShoppingEvent() {
  ReactGA4.event('click_continue_shopping', {
    message: 'Continue shopping'
  });
}
