import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import getMenusAsync from './thunk';
import State from './types';

import groupMenus from 'utils/menu';

const CODE = {
  menuProfile: 'menu-profile',
  menuHeader: 'header-menu',
  menuFooter1: 'footer-menu',
  menuFooter2: 'footer-menu-2',
  menuHotline: 'menu-hotline',
  menuNavBottom: 'header-mobile',
};

const builderMenus = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getMenusAsync.fulfilled, ($state, action) => {
    // menu profile
    const menuProfile = action.payload.find(
      (item) => item.code === CODE.menuProfile
    );

    // menu header
    const menuHeader = action.payload.find(
      (item) => item.code === CODE.menuHeader
    );

    // menu footer 1
    const menuFooter1 = action.payload.find(
      (item) => item.code === CODE.menuFooter1
    );

    // menu footer 2
    const menuFooter2 = action.payload.find(
      (item) => item.code === CODE.menuFooter2
    );

    // menu hotline
    const menuHotline = action.payload.find(
      (item) => item.code === CODE.menuHotline
    );

    // menu navbar mobile
    const menuNavBottom = action.payload.find(
      (item) => item.code === CODE.menuNavBottom
    );

    if (menuProfile) {
      $state.menuProfile = menuProfile;
    }

    if (menuHeader) {
      const convert = menuHeader.items.map((e) => {
        if (!e?.block) return e;
        return { ...e, subMenu: groupMenus(e.block.menuItems) };
      });
      $state.menuHeader = { ...menuHeader, items: convert };
    }

    if (menuFooter1) {
      $state.menuFooter1 = { ...menuFooter1, items: groupMenus(menuFooter1?.items) };
    }

    if (menuFooter2) {
      $state.menuFooter2 = { ...menuFooter2, items: groupMenus(menuFooter2?.items) };
    }

    if (menuHotline) {
      $state.menuHotline = menuHotline;
    }

    if (menuNavBottom) {
      $state.menuNavBottom = menuNavBottom;
    }
  });
};

export default builderMenus;
