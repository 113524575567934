import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getProductSkuAsync } from './thunk';
import State from './types';

export const builderCart = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProductSkuAsync.fulfilled, ($state, { payload }) => {
    $state.listProduct = $state.listProduct.reduce((prev: State['listProduct'], curr) => {
      const find = payload.find((x) => x.skuId === curr.sku.id);
      if (find) {
        return [
          ...prev,
          {
            ...curr,
            name: find.name,
            sku: {
              ...curr.sku,
              code: find.code,
              price: find.price,
              salePrice: find.salePrice,
              color: {
                ...find.color,
                media: find.media
              },
              size: find.size,
              inventoryOfGUMACOnline: find?.inventoryOfGUMACOnline
                || curr?.sku?.inventoryOfGUMACOnline || 0,
              inventory: find?.inventory || curr?.sku?.inventory || 0
            }
          }
        ];
      }
      return prev;
    }, []);
  });
};

const reducers = {
  addProductToCart($state: State, action: PayloadAction<Pick<State, 'listProduct'>>) {
    let cloneStore = [...$state.listProduct];
    const { listProduct } = action.payload;
    if (!cloneStore.length) {
      $state.listProduct = listProduct;
    }
    listProduct.forEach((item) => {
      const index = cloneStore.findIndex((product) => item?.sku?.id === product?.sku?.id);
      if (index !== -1) {
        cloneStore[index].quantity += item.quantity;
      } else {
        cloneStore = [...cloneStore, item];
      }
      $state.listProduct = cloneStore;
    });
  },
  changeQuantityProduct($state: State, action: PayloadAction<{ id: number, quantity: number }>) {
    const clone = [...$state.listProduct];
    const index = clone.findIndex((item) => item?.sku?.id === action.payload.id);
    if (index !== -1) {
      clone[index].quantity = action.payload.quantity;
      $state.listProduct = clone;
    }
  },
  removeProductCart($state: State, action: PayloadAction<number>) {
    $state.listProduct = $state.listProduct.filter((item) => item?.sku?.id !== action.payload);
  },
  toggleChecked($state: State, action: PayloadAction<number>) {
    const clone = [...$state.listProduct];
    const index = clone.findIndex((item) => item?.sku?.id === action.payload);
    if (index !== -1) {
      clone[index].isChecked = !clone[index].isChecked;
      $state.listProduct = clone;
    }
  },
  toggleCheckedAll($state: State) {
    const clone = [...$state.listProduct];
    $state.listProduct = clone.map((item) => ({
      ...item,
      isChecked: !!clone.some((i) => !i.isChecked),
    }));
  },
  setVoucherCode($state: State, action: PayloadAction<{
    code?: string;
    value?: number;
    isApplySalePrice?: boolean;
  } | undefined>) {
    $state.voucher = action.payload;
  },
  setPoint($state: State, action: PayloadAction<{
    discountPrice?: number;
    value?: number;
  } | undefined>) {
    $state.point = action.payload;
  }
};

export default reducers;
