/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { ToastContainer, toast as toastInstance, Slide } from 'react-toastify';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

const NotificationIcons = {
  success: 'circle_checked_green',
  warning: 'triangle_warning_yellow',
  error: 'circle_error_red',
} as const;

type Type = 'success' | 'warning' | 'error';
type Modifier = 'center';

export interface Props {
  modifiers?: Modifier | Modifier[];
  autoClose?: number | false;
  children?: React.ReactNode;
}

export interface ToastifyMessageProps {
  message?: string;
  description?: React.ReactNode;
  type: Type;
  autoclose?: number;
  // modifiers?: Modifier | Modifier[];
}

let currentToastId: React.ReactText | null;

export const toast = toastInstance;

export const ToastifyMessage: React.FC<ToastifyMessageProps> = ({
  type,
  message,
  description,
}) => (
  <div className="a-toastify_message">
    <Icon iconName={NotificationIcons[type] as IconName} />
    <div className="a-toastify_content u-mt-10">
      <div className="a-toastify_header">{message}</div>
      <div className="a-toastify_description">{description}</div>
    </div>
  </div>
);

export const toastSingleMode = (props: ToastifyMessageProps) => {
  if (currentToastId) {
    toastInstance.dismiss(currentToastId);
  }
  currentToastId = toastInstance(
    <ToastifyMessage {...props} />,
    {
      autoClose: props.autoclose,
    }
  );

  return currentToastId;
};

export const Toastify: React.FC<Props> = ({
  children,
  modifiers,
  autoClose = 2000,
}) => (
  <div className={mapModifiers('a-toastify', modifiers)}>
    {children}
    <ToastContainer
      hideProgressBar
      transition={Slide}
      position="top-center"
      autoClose={autoClose}
      closeOnClick={false}
      pauseOnHover
      draggable={false}
      closeButton={false}
    />
  </div>
);
