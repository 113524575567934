import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import { getPriceProduct } from './functions';

import CardProduct from 'components/molecules/CardProduct';
import useFavorite from 'hooks/useFavorite';
import { useAppDispatch } from 'store/hooks';
import { togglePopupProduct } from 'store/product';
import { baseURL } from 'utils/functions';
import PATHS from 'utils/paths';
import {
  trackingClickViewProductOnPopupEvent, ViewProductOnPopupEvent
} from 'utils/trackingGA';

interface ProductProps extends IProducts {
  isShowFlashsale?: boolean;
  combo?: {
    colorChecked?: string;
    sizeChecked?: string;
    onChangeSize?: (size: ISize) => void;
    onChangeColor?: (code: IColor) => void;
  }
}

const Product: React.FC<ProductProps> = (props) => {
  const dispatch = useAppDispatch();
  const {
    isProductFavourite,
    name,
    code,
    category,
    colors,
    slug,
    sku,
    id,
    sizes,
    combo,
    hashtags,
    sold,
    isShowFlashsale,
  } = props;

  const price = useMemo(() => getPriceProduct(sku?.[0]), [sku]);

  const [listColors, setListColors] = useState<(IColor & { isChecked: boolean })[]>([]);
  const [listSizes, setListSizes] = useState<(ISize & { isChecked: boolean })[]>([]);

  const tags = useMemo(() => {
    if (!hashtags?.length) return [];

    return hashtags?.map((e) => ({
      label: e.title,
      variant: e.color,
    }));
  }, [hashtags]);

  const listColorsMemo = useMemo(() => {
    if (!listColors?.length) return [];

    return listColors.map((e) => ({
      code: e.code,
      imgSrc: baseURL(e.media?.color?.path),
      isChecked: e.isChecked,
    }));
  }, [listColors]);

  const listThumbnail = useMemo((): string[] => {
    if (!listColors?.length) return [];

    const active = listColors?.find((e) => e.isChecked);

    if (!active?.media?.gallery?.length) return [];

    return active.media.gallery.slice(0, 2).map((x) => baseURL(x.path));
  }, [listColors]);

  const onChangeColor = useCallback((codeColor?: string) => {
    if (!codeColor || !listColors?.length) return;

    const indexOf = listColors?.findIndex((e) => e.code === codeColor);
    if (indexOf < 0 || listColors[indexOf]?.isChecked) return;

    if (combo?.onChangeColor) {
      combo.onChangeColor({
        code: listColors[indexOf]?.code,
        name: listColors[indexOf]?.name,
        media: listColors[indexOf]?.media,
      });
    }

    setListColors(listColors.map((e, i) => ({
      ...e,
      isChecked: combo?.colorChecked ? combo?.colorChecked === e?.code : i === indexOf,
    })));
  }, [listColors, combo]);

  const onChangeSize = useCallback((codeSize?: string) => {
    if (!codeSize || !listSizes?.length) return;

    const indexOf = listSizes?.findIndex((e) => e.code === codeSize);
    if (indexOf < 0 || listSizes[indexOf]?.isChecked) return;

    if (combo?.onChangeSize) {
      combo.onChangeSize({
        code: listSizes[indexOf]?.code,
        name: listSizes[indexOf]?.name,
      });
    }

    setListSizes(listSizes.map((e, i) => ({
      ...e,
      isChecked: combo?.sizeChecked ? combo?.sizeChecked === e?.code : i === indexOf,
    })));
  }, [listSizes, combo]);

  useEffect(() => {
    setListColors(colors?.map((e, i) => ({
      ...e,
      isChecked: combo?.colorChecked ? combo?.colorChecked === e?.code : i === 0,
    })));
    if (combo) {
      setListSizes(sizes?.map((e, i) => ({
        ...e,
        isChecked: combo?.sizeChecked ? combo?.sizeChecked === e?.code : i === 0,
      })));
    }
  }, [colors, sizes, combo]);

  const onAddToCart = useCallback(() => {
    const { id: productId, name: nameProduct, code: codeProduct } = props;
    const colorActive = listColors?.find((e) => e.isChecked)?.code;
    dispatch(togglePopupProduct({
      isOpenPopupAddCart: 'product',
      products: [props],
      colorChecked: colorActive,
    }));

    const dataTracking: ViewProductOnPopupEvent = {
      item_id: productId?.toString() ?? '',
      item_name: nameProduct,
      item_code: codeProduct
    };
    trackingClickViewProductOnPopupEvent(dataTracking);
  }, [listColors, dispatch, props]);

  const favorite = useFavorite(id, isProductFavourite);

  return (
    <CardProduct
      id={id}
      thumbnail={listThumbnail}
      isFlashSale={isShowFlashsale}
      isFavorite={!isShowFlashsale ? favorite.isFavorite : false}
      name={name}
      code={code}
      type={category?.name}
      typeSlug={`/${category?.slug}`}
      colors={listColorsMemo}
      price={price?.current}
      oldPrice={price?.sale}
      href={PATHS.PRODUCT_DETAIL_WITH_SLUG(category?.slug, slug)}
      handleAddToCart={onAddToCart}
      handleFavorite={!isShowFlashsale ? favorite.handle : undefined}
      handleChangeColor={onChangeColor}
      sizes={listSizes}
      handleChangeSize={onChangeSize}
      tag={tags}
      // Hidden sold quantity
      soldQuantity={false ? sold : undefined}
    // handleClick={() => {
    //   trackingViewDetailItemEvent({
    //     item_id: id.toString() ?? '',
    //     item_name: name ?? '',
    //     item_category: category.name ?? '',
    //     currency: CURRENCY_GLOBAL,
    //     item_code: code ?? '',
    //     price: sku?.[0]?.price,
    //     item_list_name: category.name ?? '',
    //     item_list_id: category.id ? String(category.id) : '',
    //     sale_price: sku?.[0].salePrice,
    //   });
    //   pixelTrackViewContent({
    //     content_ids: [code ?? ''],
    //     content_type: 'product',
    //     item_id: id.toString() ?? '',
    //     item_name: name ?? '',
    //     item_category: category.name ?? '',
    //     currency: CURRENCY_GLOBAL,
    //     item_code: code ?? '',
    //     price: sku?.[0]?.price,
    //     item_list_name: category.name ?? '',
    //     item_list_id: category.id ? String(category.id) : '',
    //     sale_price: sku?.[0].salePrice,
    //     value: sku?.[0].salePrice
    //   });
    // }}
    // percent={(isShowFlashsale && inventory) ? (sold / inventory) : 0}
    />
  );
};

export default Product;
