import { createSlice } from '@reduxjs/toolkit';

import reducers, { builderCart } from './action';
import State from './types';

const initialState: State = {
  listProduct: [],
  voucher: undefined,
  point: undefined,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers,
  extraReducers(builder) {
    builderCart(builder);
  },
});

export const {
  addProductToCart,
  changeQuantityProduct,
  removeProductCart,
  toggleChecked,
  toggleCheckedAll,
  setVoucherCode,
  setPoint,
} = cartSlice.actions;

export default cartSlice.reducer;
