import React from 'react';

import mapModifiers from 'utils/functions';

export type TextStyle = (GeneralTextStyle)[];

export interface SizeTypes {
  fontSize: string;
  lineHeight: string;
}

type SizeParams = {
  size?: SizeTypes;
  sizeMobile?: SizeTypes;
  sizeTablet?: SizeTypes;
};

export const fnSize = (
  sizeParams: SizeParams,
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string => {
  if (!sizeParams.size && !sizeParams.sizeMobile && !sizeParams.sizeTablet) {
    return mapModifiers(baseClassName, ...modifiers);
  }

  const classFont = mapModifiers(
    'u-fs',
    sizeParams.size?.fontSize ? sizeParams.size?.fontSize : undefined,
    sizeParams.sizeMobile?.fontSize ? `md-${sizeParams.sizeMobile?.fontSize}` : undefined,
    sizeParams.sizeTablet?.fontSize ? `lg-${sizeParams.sizeTablet?.fontSize}` : undefined
  ).replace('u-fs ', '');

  const classLineHeight = mapModifiers(
    'u-lh',
    sizeParams.size?.lineHeight ? sizeParams.size?.lineHeight : undefined,
    sizeParams.sizeMobile?.lineHeight ? `md-${sizeParams.sizeMobile?.lineHeight}` : undefined,
    sizeParams.sizeTablet?.lineHeight ? `lg-${sizeParams.sizeTablet?.lineHeight}` : undefined
  ).replace('u-lh ', '');

  return `${classFont} ${classLineHeight} ${mapModifiers(baseClassName, ...modifiers)}`;
};

export interface ParagraphProps extends React.HtmlHTMLAttributes<HTMLParagraphElement>, SizeParams {
  modifiers?: TextStyle;
  content?: string;
}

export interface HeadingProps extends ParagraphProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

const Heading: React.FC<HeadingProps> = ({
  modifiers,
  type = 'h5',
  content,
  children,
  size,
  sizeMobile,
  sizeTablet,
  ...props
}) => {
  const Element = type;
  return (
    content ? (
      <Element
        className={fnSize(
          {
            size,
            sizeMobile,
            sizeTablet
          },
          'a-paragraph-heading',
          modifiers
        )}
        dangerouslySetInnerHTML={{ __html: content }}
        {...props}
      />
    ) : (
      <Element
        className={fnSize(
          {
            size,
            sizeMobile,
            sizeTablet
          },
          'a-paragraph-heading',
          modifiers
        )}
        {...props}
      >
        {children}
      </Element>
    )
  );
};

Heading.defaultProps = {
  modifiers: undefined,
  content: undefined,
};

export default React.memo(Heading);
