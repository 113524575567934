import { PayloadAction } from '@reduxjs/toolkit';

import State from './types';

const reducers = {
  togglePopupProduct($state: State, action: PayloadAction<State>) {
    $state.isOpenPopupAddCart = action.payload.isOpenPopupAddCart;
    $state.colorChecked = action.payload.colorChecked || undefined;
    $state.products = action.payload.products || [];
  },
};

export default reducers;
