import { IMenuItem } from 'services/menus/types';
import { checkSlashLink, parseString } from 'utils/functions';

const linkMenu = (menu?: IMenuItem) => {
  let result: string = '';
  if (['custom_link', 'custom_image'].includes(parseString(menu?.type)) && menu?.link) {
    result = menu.link;
  }
  if (!['custom_link', 'custom_image'].includes(parseString(menu?.type)) && menu?.reference?.slug) {
    result = menu.reference.slug;
  }
  return checkSlashLink(result);
};

const hoverMenu = (menu?: IMenuItem): boolean => {
  if (menu?.menuType === 'mega' && menu?.block) {
    return true;
  }
  return false;
};

const convertMenuMobile = (menu?: IMenuItem) => ({ ...menu, subMenu: menu?.subMenu?.filter((e) => e?.type !== 'block_image') || [] });

export default {
  linkMenu,
  hoverMenu,
  convertMenuMobile,
} as const;
