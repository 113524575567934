import React from 'react';

import mapModifiers from 'utils/functions';

interface LoadingProps {
  variant?: 'fixed' | 'relative';
}

const Loading: React.FC<LoadingProps> = ({ variant = 'relative' }) => (
  <div className={mapModifiers('a-loading', variant)}>
    <div className="a-loading_a" />
    <div className="a-loading_b" />
  </div>
);

export default Loading;
