import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';

const useGaTracker = () => {
  const location = useLocation();
  const gaId = useAppSelector((state) => state.systems.systems?.ga4Id?.content);

  useEffect(() => {
    if (!gaId) return;
    setTimeout(() => {
      ReactGA4.initialize(gaId);
    }, 5000);
  }, [gaId]);

  useEffect(() => {
    if (!gaId) {
      return;
    }

    ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [gaId, location]);
};

export default useGaTracker;
