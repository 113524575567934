import {
  useState, useRef, useCallback
} from 'react';

const useToast = (
  message = '',
  time = 1500,
) => {
  const [isToast, setIsToast] = useState(false);
  const ref = useRef<NodeJS.Timeout | undefined>();

  const handleShowToast = useCallback(() => {
    setIsToast(true);
    if (ref.current) {
      clearTimeout(ref.current);
    }

    ref.current = setTimeout(() => {
      setIsToast(false);
    }, time);
  }, [time]);

  return {
    message: isToast ? message : undefined,
    handleShowToast
  };
};

export default useToast;
