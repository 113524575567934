import React from 'react';

import mapModifiers from 'utils/functions';

export interface SizePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  sizePicker: string;
  type: 'radio' | 'checkbox';
  variant?: 'secondary';
}

const SizePicker: React.FC<SizePickerProps> = ({
  sizePicker, type, id, variant, ...innerProps
}) => (

  <div
    className={mapModifiers('a-sizePicker', innerProps.disabled && 'disabled', variant)}
  >
    <label className="a-sizePicker_label" htmlFor={id}>
      <input
        {...innerProps}
        className="a-sizePicker_input"
        id={id}
        type={type}
        hidden
      />
      <div className="a-sizePicker_body">
        <span className="a-sizePicker_title">{sizePicker}</span>
      </div>
    </label>
  </div>
);

export default SizePicker;
