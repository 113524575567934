import * as yup from 'yup';

import { messageFormError } from './messageError';

import { store } from 'store';

export const phoneRegExp = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
export const characterExceptRegExp = /(<|>|{|}|\[|\])/;
// eslint-disable-next-line no-useless-escape
// const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const ACCEPT_FILE_RATING = [
  'image/png',
  'image/jpeg',
];

export const ACCEPT_FILE_RECRUITMENT = [
  'application/doc',
  'application/msword',
  'application/ms-doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];

export const schemasCheckoutForm = yup.object({
  billingAddress: yup.object({
    name: yup.string()
      .required('Vui lòng nhập tên!')
      .test(
        'name-include-character-special',
        messageFormError.canNotIncludeCharacter,
        (value) => {
          if (value) {
            const isValidName = value.search(characterExceptRegExp) >= 0;
            return !isValidName;
          }
          return true;
        }
      ),
    phone: yup
      .string()
      .required('Vui lòng nhập SĐT!')
      .matches(phoneRegExp, 'SĐT không đúng định dạng!'),
    email: yup
      .string()
      .test(
        'email-require-validate',
        'Vui lòng nhập email!',
        (val) => {
          if (val) return true;
          return !store.getState()?.auth?.profile;
        }
      )
      .test(
        'email-validate',
        'Email không đúng định dạng!',
        (value) => {
          if (value) {
            const isValidEmail = yup.string().email().isValidSync(value);
            return isValidEmail;
          }
          return true;
        }
      ).test(
        'email-include-character-special',
        messageFormError.canNotIncludeCharacter,
        (value) => {
          if (value) {
            const isValidEmail = value.search(characterExceptRegExp) >= 0;
            return !isValidEmail;
          }
          return true;
        }
      ),
    street: yup.string()
      .required('Vui lòng nhập địa chỉ!')
      .test(
        'street-include-character-special',
        messageFormError.canNotIncludeCharacter,
        (value) => {
          if (value) {
            const isValidStreet = value.search(characterExceptRegExp) >= 0;
            return !isValidStreet;
          }
          return true;
        }
      ),
    province: yup.object().nullable().required('Vui lòng chọn Tỉnh / Thành phố!'),
    district: yup.object().nullable().required('Vui lòng chọn Quận / Huyện!'),
    ward: yup.object().nullable().required('Vui lòng chọn Phường / Xã!'),
    type: yup.string().required('Vui lòng chọn nhà riêng hoặc công ty!'),
    companyName: yup.string().nullable().when('type', {
      is: '1',
      then: (schema) => schema.required('Vui lòng nhập tên công ty!'),
    })
  }),
  note: yup
    .string().notRequired()
    .test(
      'note-include-character-special',
      messageFormError.canNotIncludeCharacter,
      (value) => {
        if (value) {
          const isValidNote = value.search(characterExceptRegExp) >= 0;
          return !isValidNote;
        }
        return true;
      }
    )
  // payment: yup.number().required(),
});

export const schemasPopupAddress = yup.object({
  name: yup.string().required('Vui lòng nhập tên!'),
  phone: yup
    .string()
    .required('Vui lòng nhập SĐT!')
    .matches(phoneRegExp, 'SĐT không đúng định dạng!'),
  street: yup.string().required('Vui lòng nhập địa chỉ!'),
  province: yup.object().nullable().required('Vui lòng chọn Tỉnh / Thành phố!'),
  district: yup.object().nullable().required('Vui lòng chọn Quận / Huyện!'),
  ward: yup.object().nullable().required('Vui lòng chọn Phường / Xã!'),
});

export const schemaPolicyFeedback = yup.object({
  name: yup.string().required('Vui lòng nhập Tên của bạn'),
  email: yup.string().required('Vui lòng nhập Email').email('Email không hợp lệ'),
  phone: yup.string().required('Vui lòng nhập số điện thoại').matches(phoneRegExp, 'SĐT không đúng định dạng!'),
  title: yup.object().required('Vui lòng chọn Tiêu đề bạn muốn').nullable(),
  // fileUpload: yup.mixed().test('file', 'Vui lòng chọn file', (file) => {
  //   if (!file) return false;
  //   return true;
  // })
});

export const schemaLogin = yup.object({
  emailOrPhone: yup.string()
    .required('Số điện thoại hoặc email là bắt buộc'),
  // .test(
  //   'phoneOrEmail',
  //   'Số điện thoại hoặc email không hợp lệ',
  //   (value?: unknown) => {
  //     if (typeof value !== 'string') return false;
  //     if (!Number.isNaN(Number(value))) {
  //       return true;
  //     }
  //     const isValidEmail = yup.string().email().isValidSync(value);
  //     if (!isValidEmail) {
  //       return false;
  //     }
  //     return true;
  //   }
  // ),
  password: yup.string().required('Mật khẩu là bắt buộc'),
});

export const schemaRegister = yup.object({
  firstName: yup.string().required('Họ là bắt buộc'),
  lastName: yup.string().required('Tên là bắt buộc'),
  email: yup.string().trim()
    .email('Vui lòng nhập email hợp lệ')
    .required('Email là bắt buộc'),
  phone: yup.string().required('Số điện thoại là bắt buộc').matches(phoneRegExp, 'SĐT không đúng định dạng!'),
  password: yup.string()
    .min(8, 'Mật khẩu vui lòng sử dụng 8 ký tự trở lên')
    .required('Mật khẩu là bắt buộc'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), ''], 'Mật khẩu không khớp')
    .required('Mật khẩu là bắt buộc'),
  dobDate: yup.object().required('Ngày là bắt buộc'),
  dobMoth: yup.object().required('Tháng là bắt buộc'),
  dobYear: yup.object().required('Năm là bắt buộc'),
  // otp: yup.string().notRequired().length(6, 'Vui lòng nhập mã 6 chữ số '),
  // confirm: yup.bool().required().oneOf([true]),
  // hasOtp: yup.bool().required().oneOf([true]),
});

export const schemasEmailRegister = yup.object({
  email: yup.string().email().required()
});

export const schemasContact = yup.object({
  fullname: yup.string().required('Vui lòng nhập tên'),
  email: yup
    .string()
    .required('Vui lòng nhập email')
    .email('Email không hợp lệ'),
  phone: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
  helps: yup.object().required('Vui lòng chọn...'),
});

export const schemasEditProfile = yup.object({
  full_name: yup.string().required('Vui lòng nhập tên'),
  email: yup
    .string()
    .required('Vui lòng nhập email')
    .email('Email không hợp lệ'),
  phone: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
  gender: yup.string(),
  dobDate: yup.object().required('Ngày là bắt buộc'),
  dobMonth: yup.object().required('Tháng là bắt buộc'),
  dobYear: yup.object().required('Năm là bắt buộc'),
  otp: yup.string().when('isOtp', {
    is: true,
    then: (schema) => schema.required('Vui lòng nhập mã OTP').length(6, 'Vui lòng nhập 6 số'),
  }),
  isOtp: yup.boolean(),
});

export const schemasChangePassword = yup.object({
  oldPassword: yup.string().required('Vui lòng nhập mật khẩu cũ'),
  newPassword: yup.string().required('Vui lòng nhập mật khẩu mới').min(8, 'Mật khẩu chứa ít nhất 8 ký tự'),
  confirmPassword: yup.string()
    .when('oldPassword', {
      is: '',
      then: (schema) => schema.required('Vui lòng nhập mật khẩu cũ'),
    }).when('newPassword', {
      is: '',
      then: (schema) => schema.required('Vui lòng nhập mật khẩu mới'),
    }).oneOf([yup.ref('newPassword'), null], 'Mật khẩu không khớp')
    .required('Vui lòng nhập lại mật khẩu mới')
});
