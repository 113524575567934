import { createAsyncThunk } from '@reduxjs/toolkit';

import { staticService } from 'services/pages';
import systemsGeneralService from 'services/systems';
import { SystemsData } from 'services/systems/types';

const systemsGeneralAsync = createAsyncThunk<
  SystemsData[],
  void,
  { rejectValue: unknown }
>(
  'systems/getSystems',
  async (_, { rejectWithValue }) => {
    try {
      return await systemsGeneralService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const staticAllAsync = createAsyncThunk<
  StaticAll,
  void,
  { rejectValue: unknown }
>(
  'systems/getStatic',
  async (_, { rejectWithValue }) => {
    try {
      return await staticService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default systemsGeneralAsync;
