import { GetPageNewsByHashtagParams, PageNewsByHashtagResponse, IStaticNewsByCategory } from './types';

import { ProductRateBlocks } from 'pages/ProfileHistoryOrders/types';
import axiosInstance from 'services/common/instance';

export const staticHomeService = async <T>(): Promise<BasePageDataTypes<T>> => {
  const res = await axiosInstance.get('pages/static/home-page');
  return res.data.data;
};

export const pageService = async <T>(slug?: string): Promise<BasePageDataTypes<T>> => {
  const res = await axiosInstance.get(`pages/${slug}`);
  return res.data.data;
};

export const staticService = async (): Promise<StaticAll> => {
  const res = await axiosInstance.get('pages/static');
  return res.data.data;
};

export const getPageNewsByHashtag = async (params: GetPageNewsByHashtagParams):
  Promise<PageNewsByHashtagResponse> => {
  const res = await axiosInstance.get(`pages/static/news-by-hashtag?slug-news-hashtag=${params.slug || ''}`);
  return res.data.data;
};

export const getStaticNewsByCategory = async <T,>(
  slug?: string): Promise<IStaticNewsByCategory<T>> => {
  const params: any = {};
  params['slug-news-cat'] = slug;

  const res = await axiosInstance.get('pages/static/news-by-category', {
    params
  });

  return res.data.data;
};

export const getStaticProductRate = async (): Promise<BasePageDataTypes<ProductRateBlocks>> => {
  const res = await axiosInstance.get('pages/static/product-rate');
  return res.data.data;
};
