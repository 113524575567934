import React from 'react';

import { fnSize, ParagraphProps } from '../Heading';

interface TextProps extends ParagraphProps {
  type?: 'p' | 'span' | 'div';
  isInline?: boolean;
}

const Text: React.FC<TextProps> = ({
  modifiers,
  type = 'p',
  content,
  children,
  size,
  sizeMobile,
  sizeTablet,
  isInline,
  ...props
}) => {
  const Element = type;
  return (
    content ? (
      <Element
        className={fnSize({ size, sizeMobile, sizeTablet }, 'a-paragraph-text', modifiers, isInline && 'inline')}
        dangerouslySetInnerHTML={{ __html: content }}
        {...props}
      />
    ) : (
      <Element
        className={fnSize({ size, sizeMobile, sizeTablet }, 'a-paragraph-text', modifiers, isInline && 'inline')}
        {...props}
      >
        {children}
      </Element>
    )
  );
};

Text.defaultProps = {
  modifiers: undefined,
  content: undefined,
};

export default React.memo(Text);
