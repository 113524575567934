import { useEffect, useState } from 'react';

export default function useIsMobile() {
  const [viewport, setViewport] = useState<{
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  }>({
    isMobile: window.innerWidth < 768,
    isTablet: window.innerWidth > 767 && window.innerWidth <= 1199,
    isDesktop: window.innerWidth > 1199,
  });

  useEffect(() => {
    let debounce: NodeJS.Timeout;
    const resize = () => {
      debounce = setTimeout(() => {
        const width = window.innerWidth;
        switch (true) {
          case width < 768:
            setViewport({
              isMobile: true,
              isTablet: false,
              isDesktop: false,
            });
            break;

          case width > 767 && width <= 1199:
            setViewport({
              isMobile: false,
              isTablet: true,
              isDesktop: false,
            });
            break;

          default:
            setViewport({
              isMobile: false,
              isTablet: false,
              isDesktop: true,
            });
            break;
        }
      }, 100);
    };

    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
      clearTimeout(debounce);
    };
  }, []);

  return viewport;
}
