import { createSlice } from '@reduxjs/toolkit';

import reducers from './action';
import State from './types';

const initialState: State = {
  products: [],
  colorChecked: undefined,
  isOpenPopupAddCart: undefined,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers,
});

export const {
  togglePopupProduct,
} = productSlice.actions;

export default productSlice.reducer;
