import React from 'react';

import mapModifiers from 'utils/functions';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  imgSrc?: string;
  sizes?: 'sm' | 'md';
  type: 'radio' | 'checkbox';
}

export const ColorPicker: React.FC<Props> = ({
  imgSrc,
  id,
  sizes = 'md',
  type,
  ...innerProps
}) => (
  <div
    className={mapModifiers(
      'a-colorpicker',
      sizes,
    )}
  >
    <label className="a-colorpicker_label" htmlFor={id}>
      <input
        {...innerProps}
        className="a-colorpicker_input"
        id={id}
        type={type}
        hidden
      />
      <div className="a-colorpicker_body">
        <img src={imgSrc} alt="..." loading="lazy" />
      </div>
    </label>
  </div>
);
