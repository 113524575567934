import React, { useCallback, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { SearchInput } from '../HeaderElements';

// import CardSearch from './cardSearch';

// import Link from 'components/atoms/Link';
// import Paragraph from 'components/atoms/Paragraph';
// import useClickOutside from 'hooks/useClickOutside';
import { useAppSelector } from 'store/hooks';
import { trackingSearchEvent } from 'utils/trackingGA';
// import mapModifiers from 'utils/functions';

// const list = new Array(2).fill({
//   href: '#',
//   image: 'https://source.unsplash.com/random',
//   name: 'Áo sơ mi đính nơ nữ form rộng in couple',
//   price: '241.100 VNĐ',
// });

const Search: React.FC = () => {
  const navigate = useNavigate();
  const slugPageSearch = useAppSelector((state) => state.systems?.static?.timKiem);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSearch = useCallback((val?: string) => {
    if (val) {
      searchParams.set('keyword', val);
    } else {
      searchParams.delete('keyword');
    }
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  // const [open, setOpen] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!slugPageSearch) return;
    const valueInput = refInput?.current?.value;

    // Tracking search event
    trackingSearchEvent(valueInput || '');

    if (pathname.replace('/', '') === slugPageSearch) {
      onChangeSearch(valueInput);
    } else {
      navigate({
        pathname: `/${slugPageSearch}`,
        search: valueInput ? `keyword=${valueInput}` : undefined,
      });
    }
  }, [slugPageSearch, pathname, navigate, onChangeSearch]);

  // useClickOutside(refContainer, () => {
  //   setOpen(false);
  // });

  return (
    <div ref={refContainer} className="t-headerSearch">
      <SearchInput
        placeholder="Tìm kiếm sản phẩm"
        ref={refInput}
        handleSubmit={onSubmit}
        defaultValue={searchParams.get('keyword') || ''}
      // onChange={(e) => {
      // if (e.target.value) {
      //   setOpen(true);
      // } else {
      //   setOpen(false);
      // }
      // }}
      />
      {/* <div className={mapModifiers('t-headerSearch_suggestion', open && 'open')}>
        <div className="t-headerSearch_suggestionList">
          {
            list?.length > 0 && (
              <>
                {list.map((x, i) => (
                  <div className="t-headerSearch_suggestionItem" key={`item-${i.toString()}`}>
                    <CardSearch {...x} />
                  </div>
                ))}
                <div className="u-mt-14">
                  <Link href="#">
                    <Paragraph.Text
                      size={{
                        fontSize: '20',
                        lineHeight: '24'
                      }}
                      modifiers={['celadonBlue', '500', 'center']}
                      content="Xem tất cả (4)"
                    />
                  </Link>
                </div>
              </>
            )
          }
          {
            list?.length <= 0 && (
              <Paragraph.Text
                size={{
                  fontSize: '18',
                  lineHeight: '22'
                }}
                modifiers={['raisinBlack', 'center']}
                content="Sản phẩm tìm kiếm không có ..."
              />
            )
          }
        </div>
      </div> */}
    </div>
  );
};

export default Search;
