import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getProfileAsync } from './thunk';
import State from './types';

import { IProfile } from 'services/auth/types';

const builderProfile = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(getProfileAsync.pending, ($state) => {
    $state.loading = true;
  });
  builder.addCase(getProfileAsync.fulfilled, ($state, action) => {
    $state.status = 'AUTH';
    $state.loading = false;
    $state.profile = action.payload;
  });
  builder.addCase(getProfileAsync.rejected, ($state) => {
    $state.status = 'UNAUTH';
    $state.loading = false;
    $state.profile = undefined;
  });
};

export const reducers = {
  setAccountInfo($state: State, action: PayloadAction<IProfile>) {
    $state.profile = action.payload;
    $state.status = 'AUTH';
  },
  removeAccount($state: State) {
    $state.profile = undefined;
    $state.status = 'UNAUTH';
    $state.loading = false;
  }
};

export default builderProfile;
