import {
  IProfile,
  VerifyPhonePayload,
  IVerifyPhone,
  RegisterPayload,
  LoginPayload,
  ILogin,
  ILoginSocial,
  ISocialProvider,
  ChangePasswordPayload,
  PostForgetPasswordPayload,
  PostVerifyPayload,
  IVerifyPassword,
  PostResetPasswordPayload,
  PostUpdateProfilePayload
} from './types';

import axiosInstance from 'services/common/instance';

const getProfileService = async (): Promise<IProfile> => {
  const res = await axiosInstance.get('customers/get-profile');
  return res.data.data;
};

export const postVerifyPhoneNumber = async (params: VerifyPhonePayload): Promise<IVerifyPhone> => {
  const res = await axiosInstance.post('customers/verify-phone-number', params);
  return res.data.data;
};

export const postCustomerRegister = async (params: RegisterPayload): Promise<void> => {
  await axiosInstance.post('customers/register', params);
};

export const postCustomerLogin = async (params: LoginPayload): Promise<ILogin> => {
  const res = await axiosInstance.post('customers/login', params);
  return res.data.data;
};

export const customerLogoutService = async (): Promise<unknown> => {
  const res = await axiosInstance.get('customers/logout');
  return res.data;
};

export const customerLoginSocialService = async (
  provider: ISocialProvider
): Promise<ILoginSocial> => {
  const res = await axiosInstance.get(`customers/login-social/${provider}`);
  return res.data.data;
};

export const postChangePassword = async (payload: ChangePasswordPayload): Promise<IProfile> => {
  const res = await axiosInstance.post('customers/change-password', payload);
  return res.data.data;
};

export default getProfileService;

export const postForgetPassword = async (payload: PostForgetPasswordPayload): Promise<void> => {
  await axiosInstance.post('customers/forget-password', payload);
};

export const postVerifyOtp = async (payload: PostVerifyPayload): Promise<IVerifyPassword> => {
  const res = await axiosInstance.post('customers/verify-otp', payload);
  return res.data.data;
};

export const postResetPassword = async (payload: PostResetPasswordPayload): Promise<void> => {
  await axiosInstance.post('customers/reset-password', payload);
};

export const postUpdateProfile = async (payload: PostUpdateProfilePayload): Promise<IProfile> => {
  const res = await axiosInstance.post('customers/update-profile', payload);
  return res.data.data;
};
