import { PayloadAction } from '@reduxjs/toolkit';

import State from './types';

export const reducers = {
  updateFavoriteLocalAction($state: State, action: PayloadAction<{ id: number, flag: boolean }>) {
    if (!action.payload.flag) {
      $state.local = $state.local.filter((x) => x.id !== action.payload.id);
    }
    if (action.payload.flag) {
      $state.local.push(action.payload);
    }
  },
  updateFavoriteAuthAction($state: State, action: PayloadAction<{ id: number, flag: boolean }>) {
    const findIndex = $state.auth.findIndex((x) => x.id === action.payload.id);
    if (findIndex !== -1) {
      $state.auth[findIndex].flag = action.payload.flag;
    } else {
      $state.auth.push(action.payload);
    }
  },
  clearFavoriteLocalAction($state: State) {
    $state.local = [];
  },
  clearFavoriteAuthAction($state: State) {
    $state.auth = [];
  }
};

export const placeholder = null;
