import React, { useState, useEffect, useMemo } from 'react';

import banner from 'assets/images/popupSizeGuide/bannerPopupSizeGuide.png';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import InputRange from 'components/atoms/InputRange';
import Loading from 'components/atoms/Loading';
import Paragraph from 'components/atoms/Paragraph';
import Popup from 'components/organisms/Popup';
import { useAsync } from 'hooks/useAsync';
import useDebounce from 'hooks/useDebounce';
import { getDetailCategoryProductService, getSizeConsultationService } from 'services/product';
import { convertMeterToCentimeter } from 'utils/functions';

interface PopupSizeGuideProps {
  handleClose?: () => void;
  isOpen?: boolean;
  category?: {
    id?: number;
    slug?: string;
  };
  imgSizeGuide?: string;
}

const PopupSizeGuide: React.FC<PopupSizeGuideProps> = ({
  handleClose,
  isOpen,
  category,
  imgSizeGuide,
}) => {
  const [heightValue, setHeightValue] = useState<number>(0);
  const [weightValue, setWeightValue] = useState<number>(0);
  const [activeGuide, setActiveGuide] = useState<boolean>(false);

  const [executeCategory, stateCategory] = useAsync(
    getDetailCategoryProductService,
    {
      onSuccess: (res) => {
        if (res?.sizeConsultActive) {
          setHeightValue(res.scHeightMin || 0);
          setWeightValue(res.scWeightMin || 0);
        }
      }
    }
  );

  const [executeSize, stateSize] = useAsync(getSizeConsultationService);

  const sizeConsultActive = useMemo(() => stateCategory.data?.sizeConsultActive, [stateCategory]);

  useEffect(() => {
    setActiveGuide(!!sizeConsultActive);
  }, [sizeConsultActive]);

  useEffect(() => {
    if (isOpen && category?.slug) {
      executeCategory(category.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, category?.slug]);

  useDebounce(() => {
    if (heightValue && weightValue && category?.id) {
      executeSize(
        category?.id,
        {
          height: heightValue,
          weight: weightValue
        }
      );
    }
  }, 750, [heightValue, weightValue, category?.id]);

  return (
    <Popup
      variant="sizeGuide"
      isOpen={!!isOpen}
      handleClose={handleClose}
      iconName="close_gray"
    >
      {stateCategory.loading && (
        <div className="d-flex justify-content-center u-pt-40 u-pb-80">
          <Loading />
        </div>
      )}
      {!stateCategory.loading && (
        <div className="t-popupSizeGuide">
          <div className="t-popupSizeGuide_menu u-pt-3 u-pb-3 u-pl-3 u-pr-3 row">
            <div
              className={`t-popupSizeGuide_menu-active ${sizeConsultActive ? '' : 'full'} ${activeGuide ? 'left' : 'right'}`}
            />
            {sizeConsultActive && (
              <div
                className="t-popupSizeGuide_menu-tab col-6"
                onClick={() => sizeConsultActive && setActiveGuide(!activeGuide)}
              >
                <Paragraph.Heading
                  content="Hướng dẫn"
                  size={{ fontSize: '16', lineHeight: '19' }}
                  modifiers={activeGuide ? ['white'] : ['raisinBlack']}
                />
              </div>
            )}
            <div
              className={`t-popupSizeGuide_menu-tab ${sizeConsultActive ? 'col-6' : ''}`}
              onClick={() => sizeConsultActive && setActiveGuide(!activeGuide)}
            >
              <Paragraph.Heading
                content="Chọn size"
                size={{ fontSize: '16', lineHeight: '19' }}
                modifiers={!activeGuide ? ['white'] : ['raisinBlack']}
              />
            </div>
          </div>
          {sizeConsultActive && activeGuide && (
            <div className="t-popupSizeGuide_table u-mt-lg-25 u-mt-md-66 u-mt-30 animate animate-fadeIn">
              <div className="t-popupSizeGuide_table-info u-pt-lg-40 u-pl-lg-60 u-pb-lg-78 u-pl-md-65 u-pr-md-65">
                <div className="u-mb-lg-32 u-mb-md-25 u-mb-35">
                  <Paragraph.Text
                    content="Hãy cho GUMAC biết"
                    size={{ fontSize: '36', lineHeight: '48' }}
                    sizeMobile={{ fontSize: '24', lineHeight: '30' }}
                    modifiers={['700', 'raisinBlack']}
                  />
                </div>
                <div className="t-popupSizeGuide_range">
                  <InputRange
                    label="Chiều cao"
                    minValue={stateCategory.data?.scHeightMin}
                    maxValue={stateCategory.data?.scHeightMax}
                    value={heightValue}
                    labelValue={convertMeterToCentimeter(heightValue || 0)}
                    onChange={(value) => setHeightValue(value)}
                  />
                  <InputRange
                    label="Cân Nặng"
                    minValue={stateCategory.data?.scWeightMin}
                    maxValue={stateCategory.data?.scWeightMax}
                    value={weightValue}
                    labelValue={`${weightValue || 0}kg`}
                    onChange={(value) => setWeightValue(value)}
                  />
                </div>
                <div className={`t-popupSizeGuide_suggest ${stateSize?.data?.name ? '' : 'hidden'}`}>
                  <div className="d-flex t-popupSizeGuide_suggest-title">
                    <Icon iconName="fire_work" iconSize="24" />
                    <Paragraph.Text
                      content="GUMAC gợi ý"
                      size={{ fontSize: '16', lineHeight: '21' }}
                      modifiers={['raisinBlack']}
                    />
                  </div>
                  <div className="u-mt-16">
                    <Paragraph.Text
                      content={`Lựa chọn sản phẩm SIZE ${stateSize?.data?.name}`}
                      size={{ fontSize: '32', lineHeight: '38' }}
                      sizeMobile={{ fontSize: '24', lineHeight: '29' }}
                      modifiers={['500', 'vividCerulean']}
                    />
                  </div>
                </div>
              </div>
              <div className="t-popupSizeGuide_table-img d-md-flex d-none align-items-end">
                <Image src={banner} ratio="743x570" />
              </div>
            </div>
          )}
          {!activeGuide && imgSizeGuide && (
            <div className="t-popupSizeGuide_thumbnailSize u-mt-20 u-mb-20">
              <img src={imgSizeGuide} alt="huong-dan-chon-size" />
            </div>
          )}
          {!activeGuide && !imgSizeGuide && (
            <div className="u-pb-20 u-pt-20">
              <Paragraph.Text
                content="Chưa có hướng dẫn chọn size"
                size={{ fontSize: '20', lineHeight: '28' }}
                sizeMobile={{ fontSize: '16', lineHeight: '24' }}
                modifiers={['500', 'spanishCrimson', 'center']}
              />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default PopupSizeGuide;
