export const NEWS_CATEGORY_SLUG = 'danh-muc-tin-tuc';
const PATHS = {
  HOME: '/',
  PAGE: '/:slug',
  COLLECTION_DETAIL_WITH_SLUG: (slug?: string) => `/bo-suu-tap/${slug}`,
  PRODUCT_DETAIL_WITH_SLUG: (category?: string, slug?: string) => `/${category}/${slug}`,
  MATERIAL_DETAIL_WITH_SLUG: (slug?: string) => `/chat-lieu/${slug}`,
  NEWS_DETAIL_WITH_SLUG: (slug?: string) => `/tin-tuc/${slug}`,
  // NEWS_BY_HASHTAG_WITH_SLUG: (slug?: string) => `/nhan-tin-tuc/${slug}`,
  NEWS_CATEGORY: `/${NEWS_CATEGORY_SLUG}`,
  NEWS_CATEGORY_WITH_SLUG: (slug?: string) => (slug ? `${PATHS.NEWS_CATEGORY}/${slug}` : PATHS.NEWS_CATEGORY),
  SALE_OFF: '/sale-off',
  SALE_OFF_WITH_SLUG: (slug?: string) => `${PATHS.SALE_OFF}/${slug}`,
  REDIRECT: '/redirect',
  VNPAY: '/vnpay',
  MOMO: '/momo'
} as const;

export default PATHS;
