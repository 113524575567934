import React from 'react';
import Modal from 'react-modal';

import Icon, { IconName, IconSize } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

export type VariantModal =
  | 'default'
  | 'authenticate'
  | 'voucher'
  | 'address'
  | 'gallery'
  | 'product'
  | 'voucherCustomer'
  | 'combo'
  | 'confirm'
  | 'addressList'
  | 'checkStore'
  | 'sizeGuide'
  | 'removeOrder'
  | 'recruitment';

interface PopupProps {
  isOpen: boolean;
  handleClose?: () => void;
  className?: string;
  iconName?: IconName;
  children: React.ReactNode;
  variant?: VariantModal;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  sizeClose?: IconSize;
}

const Popup: React.FC<PopupProps> = ({
  isOpen,
  handleClose,
  children,
  className,
  variant,
  iconName,
  headerComponent,
  footerComponent,
  sizeClose,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={handleClose}
    closeTimeoutMS={250}
    className={mapModifiers('o-popup', className, variant)}
    appElement={document.getElementById('root') as HTMLElement}
    ariaHideApp={false}
    portalClassName={mapModifiers('o-popup_portal', isOpen && 'open')}
  >
    <div className="o-popup_main">
      <div className="o-popup_wrapper">
        {handleClose && (
          <button type="button" className="o-popup_close" onClick={handleClose}>
            <Icon iconName={iconName || 'close_black'} iconSize={sizeClose} />
          </button>
        )}
        {headerComponent && (
          <div className="o-popup_header">{headerComponent}</div>
        )}
        <div className="o-popup_body">{children}</div>
        {footerComponent && (
          <div className="o-popup_footer">{footerComponent}</div>
        )}
      </div>
    </div>
  </Modal>
);

Popup.defaultProps = {
  handleClose: undefined,
  className: undefined,
  iconName: 'close_black',
  variant: 'default',
  headerComponent: undefined,
  footerComponent: undefined,
  sizeClose: '16',
};

export default Popup;
