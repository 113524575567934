import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { Settings } from 'react-slick';

import {
  getPriceProduct,
  getSizesProduct,
  getThumbnailProduct,
  getColorsProduct,
  getCurrentSkuProduct
} from '../Product/functions';

import Button from 'components/atoms/Button';
import { ColorPicker } from 'components/atoms/ColorPicker';
import Icon from 'components/atoms/Icon';
import Paragraph from 'components/atoms/Paragraph';
import Quantity from 'components/atoms/Quantity';
import SizePicker from 'components/atoms/SizePicker';
import { toastSingleMode } from 'components/atoms/Toastify';
import { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import Popup from 'components/organisms/Popup';
import { StarList } from 'components/organisms/RatingProduct';
import PopupSizeGuide from 'components/templates/PopupSizeGuide';
import SliderImages from 'components/templates/ProductDetailItem/SliderImages';
import { useAsync } from 'hooks/useAsync';
import useFavorite from 'hooks/useFavorite';
import useToast from 'hooks/useToast';
import { getBranchService } from 'services/branch';
import { addProductToCart } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { togglePopupProduct } from 'store/product';
import { CURRENCY_GLOBAL } from 'utils/constants';
import { baseURL, parseString } from 'utils/functions';
import { pixelTrackAddToCart } from 'utils/trackingFBP';
import { ProductCartEventTypes, trackingAddToCartEvent } from 'utils/trackingGA';

const PopupProduct: React.FC = () => {
  const { products, isOpenPopupAddCart, colorChecked } = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();

  const [colorActive, setColorActive] = useState<string>();
  const [sizeActive, setSizeActive] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1);
  const [isShowPopupSize, setShowPopupSize] = useState(false);

  const currentSku = useMemo(
    () => getCurrentSkuProduct(products?.[0], sizeActive, colorActive),
    [products, sizeActive, colorActive]
  );
  const price = useMemo(() => getPriceProduct(currentSku), [currentSku]);
  const sizes = useMemo(() => getSizesProduct(products?.[0]), [products]);
  const colors = useMemo(() => getColorsProduct(products?.[0]), [products]);
  const favorite = useFavorite(products?.[0]?.id, products?.[0]?.isProductFavourite);

  const listThumbnail = useMemo(() => getThumbnailProduct({
    product: products?.[0],
    colorChecked: colorActive,
    sliceThumbnailFrom: 2
  }), [products, colorActive]);

  const thumbnailSettings: Settings = useMemo(() => ({
    dots: false,
    arrows: false,
    infinite: listThumbnail && listThumbnail?.length > 3,
    slidesToScroll: 1,
    slidesToShow: 3,
    focusOnSelect: true,
    swipeToSlide: true,
    vertical: true,
    prevArrow: <PrevArrow customArrow="transparentBlue" />,
    nextArrow: <NextArrow customArrow="transparentBlue" />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          infinite: listThumbnail && listThumbnail?.length > 5,
          slidesToShow: 5,
          slidesToScroll: 1,
          vertical: false,
          arrows: true
        },
      },
      {
        breakpoint: 920,
        settings: {
          infinite: listThumbnail && listThumbnail?.length > 4,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: listThumbnail && listThumbnail?.length > 5,
          slidesToShow: 5,
          slidesToScroll: 1,
          vertical: false,
          arrows: true
        },
      },
    ],
  }), [listThumbnail]);

  const [executeBranchs, stateBranchs] = useAsync(getBranchService);

  const isEmpty = useMemo(() => !stateBranchs?.data?.length, [stateBranchs]);

  const unValidated = useMemo(
    () => currentSku?.salePrice === undefined || currentSku?.salePrice === null,
    [currentSku?.salePrice]
  );

  const inventory = useMemo(
    () => (stateBranchs.data || []).reduce((prev, curr) => prev + Number(curr.inventory || 0), 0),
    [stateBranchs]
  );

  const onClosePopup = useCallback(() => {
    setQuantity(1);
    dispatch(togglePopupProduct({ isOpenPopupAddCart: undefined }));
  }, [dispatch]);

  const onAddToCart = useCallback(() => {
    if (currentSku) {
      dispatch(addProductToCart({
        listProduct: [{
          name: parseString(products?.[0]?.name),
          code: parseString(products?.[0]?.code),
          quantity,
          sku: {
            ...currentSku,
            inventory,
          },
          isChecked: true,
          categorySlug: products?.[0]?.category?.slug,
          productSlug: products?.[0]?.slug
        }]
      }));
      toastSingleMode({
        type: 'success',
        message: 'Thêm sản phẩm thành công'
      });
      onClosePopup();

      // Tracking GA
      if (products) {
        const dataTracking: ProductCartEventTypes = {
          items: [
            {
              item_name: products[0]?.name ?? '',
              item_id: currentSku.id ? String(currentSku.id) : '',
              item_code: products[0]?.code ?? '',
              item_category: products[0]?.category?.name ?? '',
              currency: CURRENCY_GLOBAL,
              price: currentSku.price,
              sale_price: currentSku.salePrice,
              item_sku_code: currentSku.code ?? '',
            }
          ]
        };
        trackingAddToCartEvent(dataTracking);
        pixelTrackAddToCart({
          content_type: 'product',
          content_ids: [currentSku.code ?? ''],
          content_name: products[0]?.name,
          content_category: products[0]?.category?.name || '',
          value: currentSku.salePrice,
          currency: CURRENCY_GLOBAL,
          price: currentSku.price,
          sale_price: currentSku.salePrice,
          item_sku_code: currentSku.code ?? '',
        });
      }
    }
  }, [quantity, currentSku, inventory, products, onClosePopup, dispatch]);

  useEffect(() => {
    setColorActive(colorChecked || colors?.[0]?.value);
    setSizeActive(sizes?.[0]?.value);
  }, [colorChecked, sizes, colors]);

  useEffect(() => {
    if (isOpenPopupAddCart === 'product' && currentSku) {
      setQuantity(1);
      executeBranchs({
        sku_code: currentSku.code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenPopupAddCart, currentSku]);

  const {
    message: messageExceedInventory,
    handleShowToast: handleShowToastExceedInventory
  } = useToast('Bạn không được đặt vượt quá số lượng tồn kho');

  return (
    <>
      <Popup
        isOpen={isOpenPopupAddCart === 'product'}
        sizeClose="24"
        iconName="close_circle"
        handleClose={onClosePopup}
        variant="product"
      >
        <div className="t-popupProduct">
          <div className="t-popupProduct_left">
            <SliderImages
              customThumbnailSettings={thumbnailSettings}
              list={listThumbnail || []}
              productName={products?.[0]?.name}
              productId={products?.[0]?.id}
              category={products?.[0]?.category?.name}
            />
          </div>
          <div className="t-popupProduct_right">
            <Paragraph.Heading
              type="h2"
              size={{ fontSize: '20', lineHeight: '32' }}
              sizeMobile={{ fontSize: '16', lineHeight: '24' }}
              modifiers={['700']}
              content={`${products?.[0]?.name} ${products?.[0]?.code}`}
            />

            <div className="d-flex align-items-center u-mt-12 u-mt-lg-16">
              <div className="d-none d-md-block u-mr-30">
                <Paragraph.Text
                  size={{ fontSize: '16', lineHeight: '21' }}
                  modifiers={['raisinBlack05']}
                  content={products?.[0]?.code}
                />
              </div>
              {/* TODO: Update rating later */}
              <div style={{ minHeight: '21px' }} className="d-flex align-items-center">
                <StarList
                  rate={5}
                  variant="star_orange"
                />
                {!stateBranchs.loading && <div style={{ fontSize: '14px' }} className="u-ml-8 u-mr-8">|</div>}
                {
                  !stateBranchs.loading
                  && (
                    <div style={{ opacity: stateBranchs?.loading ? '0.3' : '1' }}>
                      <Paragraph.Text
                        size={{ fontSize: '16', lineHeight: '19' }}
                        modifiers={[isEmpty ? 'spanishCrimson' : 'celadonBlue']}
                      >
                        {isEmpty ? 'Hết hàng' : 'Còn hàng'}
                      </Paragraph.Text>
                    </div>
                  )
                }
              </div>
              {/* Ẩn thông tin lượt mua */}
              {/* <div className="d-flex align-items-center">
                <div className="d-block d-md-none u-mr-4">
                  <Paragraph.Text size={{ fontSize: '16', lineHeight: '19' }}
                  modifiers={['raisinBlack05']} content="Đã có: " />
                </div>
                <Paragraph.Text size={{ fontSize: '16', lineHeight: '19' }}
                modifiers={['celadonBlue']} content={`${products?.[0]?.sold}`} />
                <div className="u-ml-4" />
                <Paragraph.Text size={{ fontSize: '16', lineHeight: '19' }}
                modifiers={['raisinBlack05']} content="lượt mua" />
              </div>
              <div className="u-ml-60 u-ml-24"
              style={{ opacity: stateBranchs?.loading ? '0.3' : '1' }}> */}

            </div>
            <div className="d-flex d-md-none align-items-center u-mt-12">
              <Paragraph.Text
                size={{ fontSize: '20', lineHeight: '24' }}
                modifiers={['spanishCrimson', '500']}
                content={price?.current}
              />
              {price?.sale && (
                <div className="u-ml-35 u-ml-lg-6">
                  <Paragraph.Text
                    size={{ fontSize: '16', lineHeight: '19' }}
                    modifiers={['raisinBlack03', 'lineThrough']}
                    content={price?.sale}
                  />
                </div>
              )}
            </div>
            <div className="d-none d-md-flex align-items-center u-mt-16">
              <Paragraph.Text
                size={{ fontSize: '20', lineHeight: '24' }}
                modifiers={['spanishCrimson', '500']}
                content={price?.current}
              />
              {price?.sale && (
                <div className="u-ml-35 u-ml-lg-6">
                  <Paragraph.Text
                    size={{ fontSize: '16', lineHeight: '19' }}
                    modifiers={['raisinBlack03', 'lineThrough']}
                    content={price?.sale}
                  />
                </div>
              )}
            </div>

            <div className="d-flex align-items-center u-mt-16 u-mt-md-22">
              <Paragraph.Text
                size={{ fontSize: '17', lineHeight: '20' }}
                modifiers={['raisinBlack07']}
                content="Kích thước"
              />
              <div className="u-ml-lg-83 u-ml-0 u-ml-md-24  d-none d-md-block">
                <div role="button" onClick={() => setShowPopupSize(true)}>
                  <Paragraph.Text
                    size={{ fontSize: '16', lineHeight: '19' }}
                    modifiers={['celadonBlue', 'underline']}
                    content="Hướng dẫn chọn size"
                    role="button"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center u-mt-7 u-mt-md-12 u-ml-negative-8 u-mr-negative-8 u-ml-negative-md-16 u-mr-negative-md-16">
              {sizes?.map((x, i) => (
                <div className="u-pl-8 u-pr-8 u-pl-md-16 u-pr-md-16" key={`size-${i.toString()}`}>
                  <SizePicker
                    type="radio"
                    checked={sizeActive === x.value}
                    sizePicker={parseString(x.label)}
                    onChange={() => setSizeActive(x.value)}
                  />
                </div>
              ))}
            </div>

            <div className="u-mt-18 d-block d-md-none">
              <div role="button" onClick={() => setShowPopupSize(true)}>
                <Paragraph.Text
                  size={{ fontSize: '16', lineHeight: '19' }}
                  modifiers={['celadonBlue', 'underline']}
                  content="Hướng dẫn chọn size"
                  role="button"
                />
              </div>
            </div>

            <div className="u-mt-16">
              <Paragraph.Text
                size={{ fontSize: '17', lineHeight: '20' }}
                modifiers={['raisinBlack07']}
                content="Màu sắc"
              />
              <div className="d-flex align-items-center u-mt-7 u-mt-md-12 u-ml-negative-8 u-mr-negative-8 u-ml-negative-md-16 u-mr-negative-md-16">
                {
                  colors?.map((x, i) => (
                    <div key={`color-${i.toString()}`} className="u-pl-8 u-pr-8 u-pl-md-16 u-pr-md-16">
                      <ColorPicker
                        sizes="md"
                        imgSrc={x.imgSrc}
                        type="radio"
                        checked={colorActive === x.value}
                        onChange={() => setColorActive(x.value)}
                      />
                    </div>
                  ))
                }
              </div>
            </div>

            {messageExceedInventory && (
              <div className="u-mt-14">
                <Paragraph.Text
                  content={messageExceedInventory}
                  size={{
                    fontSize: '14',
                    lineHeight: '18'
                  }}
                  modifiers={['spanishCrimson']}
                />
              </div>
            )}

            <div className="u-mt-14 u-mt-md-24 d-flex align-items-center">
              <div className="t-productDetailInfo_quantity">
                <Quantity
                  disabled={isEmpty || stateBranchs?.loading || unValidated}
                  quantity={quantity}
                  handleDecreaseQuantity={() => {
                    if (quantity > 1) {
                      setQuantity((val) => val - 1);
                    }
                  }}
                  handleIncreaseQuantity={() => {
                    if (quantity < inventory) {
                      setQuantity((val) => val + 1);
                    } else {
                      handleShowToastExceedInventory();
                    }
                  }}
                />
              </div>
              <div className="flex-grow-1 u-ml-24 u-mr-16 u-mr-md-20 u-mr-lg-25">
                <Button
                  modifiers={['dark', 'sm']}
                  onClick={onAddToCart}
                  disabled={isEmpty || stateBranchs?.loading || unValidated}
                >
                  <div className="u-mr-12 d-none d-md-block">
                    <Icon iconName="add_cart_circle_blue" iconSize="25" />
                  </div>
                  <Paragraph.Text modifiers={['white', 'uppercase', 's01']} size={{ fontSize: '16', lineHeight: '19', }}>
                    Thêm vào giỏ
                  </Paragraph.Text>
                </Button>
              </div>
              <div className="favorite" role="button" onClick={favorite.handle}>
                <Icon iconName={favorite.isFavorite ? 'love_red' : 'love_white'} iconSize={favorite.isFavorite ? '32' : '30'} />
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <PopupSizeGuide
        isOpen={isShowPopupSize}
        handleClose={() => setShowPopupSize(false)}
        category={{
          id: products?.[0]?.category?.root?.id,
          slug: products?.[0]?.category?.root?.slug,
        }}
        imgSizeGuide={baseURL(products?.[0]?.category?.sizeGuideImage?.path)}
      />
    </>
  );
};

PopupProduct.defaultProps = {
};
export default PopupProduct;
