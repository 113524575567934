/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo } from 'react';
import {
  useLocation, useMatch, useNavigate, useSearchParams
} from 'react-router-dom';

import { MESSAGE_TYPE } from './types';

import { toastSingleMode } from 'components/atoms/Toastify';
import { setAccessToken } from 'services/common/storage';
import { getProfileAsync } from 'store/auth/thunk';
import { useAppDispatch } from 'store/hooks';
import PATHS from 'utils/paths';

interface SocialLoginProviderProps {
  children: React.ReactNode;
}

const SocialLoginProvider: React.FC<SocialLoginProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectURLMatched = useMatch({
    path: PATHS.REDIRECT,
  });

  const matchedSocialRedirectURL = useMemo(() => {
    if (!redirectURLMatched) return false;
    const actionRedirect = searchParams.get('action');
    if (actionRedirect !== MESSAGE_TYPE) {
      return false;
    }

    return true;
  }, [redirectURLMatched, searchParams]);

  useEffect(() => {
    const accessToken = searchParams.get('token');

    if (matchedSocialRedirectURL && accessToken) {
      window.opener?.postMessage(
        {
          type: MESSAGE_TYPE,
          payload: {
            accessToken,
          },
        },
        window.origin
      );
      window.close();
    }
  }, [matchedSocialRedirectURL, location.search, searchParams]);

  useEffect(() => {
    const onSocialLoginSuccessRedirect = (
      event: MessageEvent<any>
    ) => {
      const { origin, data } = event;

      if (matchedSocialRedirectURL) return;
      if (origin !== window.origin) return;
      if (data?.type !== MESSAGE_TYPE) return;

      const { accessToken } = data.payload;
      setAccessToken(accessToken);
      dispatch(getProfileAsync());
      navigate(PATHS.HOME);
      toastSingleMode({
        type: 'success',
        message: 'Thành công',
        description: 'Đăng nhập thành công',
      });
    };

    window.addEventListener('message', onSocialLoginSuccessRedirect);
    return () => {
      window.removeEventListener('message', onSocialLoginSuccessRedirect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedSocialRedirectURL]);

  return <>{children}</>;
};

export default SocialLoginProvider;
