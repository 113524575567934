import { EmailRegisterResponse } from './types';

import axiosInstance from 'services/common/instance';

const postEmailRegister = async (
  params?: { email: string }
): Promise<EmailRegisterResponse> => {
  const res = await axiosInstance.post('email-register', params);
  return res.data;
};

export default postEmailRegister;
