import React, { useEffect, useRef, useState } from 'react';

import IconPlayer from 'assets/icons/ic_play_blue.svg';
import Image from 'components/atoms/Image';
import mapModifiers from 'utils/functions';

export interface PlayerProps {
  videoSrc: string;
  thumbnailSrc: string;
  thumbnailMobile?: string;
  thumbnailSrcTablet?: string;
  isLoop?: boolean;
  isMuted?: boolean;
  autoplay?: boolean;
  controls?: boolean;
  thumbnailRatio?: Ratio;
  type?: 'default';
  isYoutube?: boolean;
}

const Player: React.FC<PlayerProps> = ({
  videoSrc,
  autoplay,
  isLoop,
  isMuted,
  controls = true,
  thumbnailSrc,
  thumbnailMobile,
  thumbnailSrcTablet,
  thumbnailRatio,
  type,
  isYoutube,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setPlay] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlay) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlay]);

  return (
    <div
      className={mapModifiers('o-player', type)}
    >
      <div
        onClick={() => setPlay(true)}
        className={mapModifiers('o-player_layer', (isPlay || isYoutube) && 'playing')}
      >
        <Image
          src={thumbnailSrc}
          srcMobile={thumbnailMobile}
          srcTablet={thumbnailSrcTablet}
          ratio={thumbnailRatio || '16x9'}
        />
        <div className="o-player_player-icon">
          <img src={IconPlayer} alt="player_button" />
        </div>
      </div>
      <div
        className={mapModifiers('o-player_video', (isPlay || isYoutube) && 'playing')}
      >
        {
          // eslint-disable-next-line react/no-danger
          isYoutube ? <div className="o-player_video-youtube" dangerouslySetInnerHTML={{ __html: videoSrc }} />
            : (
              <video
                ref={videoRef}
                controls={controls}
                playsInline
                loop={isLoop}
                muted={isMuted}
                autoPlay={autoplay}
              >
                <source
                  src={videoSrc}
                  type="video/mp4"
                />
                Trình duyệt của bạn không hỗ trợ phát video,
                vui lòng sử dụng trình duyệt khác. Xin cảm ơn.

                <track kind="captions" />
              </video>
            )
        }
      </div>
    </div>
  );
};

Player.defaultProps = {
};

export default Player;
