import { createSlice } from '@reduxjs/toolkit';

import builderMenus from './action';
import State from './types';

const initialState: State = {
  menuProfile: undefined,
  menuHeader: undefined,
  menuFooter1: undefined,
  menuFooter2: undefined,
  menuNavBottom: undefined,
};

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builderMenus(builder);
  },
});

export default menusSlice.reducer;
