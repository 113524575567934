import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { PersistedState } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth';
import cartReducer from './cart';
import customersReducer from './customers';
import favoriteReducer from './favorite';
import menusReducer from './menus';
import productReducer from './product';
import systemsReducer from './systems';

const PersistVersion = 3;

const persistConfig = {
  key: 'gumac-e',
  storage,
  version: PersistVersion,
  whitelist: ['systems', 'menus'],
  blacklist: ['favorite'],
  migrate: (state: PersistedState) => {
    // eslint-disable-next-line no-underscore-dangle
    if (PersistVersion !== state?._persist.version) {
      return Promise.resolve((null as unknown) as PersistedState);
    }
    return Promise.resolve(state);
  },
};

const favoritePersistConfig = {
  key: 'gumac-e-favorite',
  storage,
  version: PersistVersion,
  whitelist: ['local'],
};

const cartPersistConfig = {
  key: 'gumac-e-cart',
  storage,
  version: PersistVersion,
  whitelist: ['listProduct'],
};

const rootReducer = combineReducers({
  systems: systemsReducer,
  menus: menusReducer,
  auth: authReducer,
  customers: customersReducer,
  favorite: persistReducer(favoritePersistConfig, favoriteReducer),
  product: productReducer,
  cart: persistReducer(cartPersistConfig, cartReducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
