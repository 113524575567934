/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { ColorPicker } from 'components/atoms/ColorPicker';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Paragraph from 'components/atoms/Paragraph';
import SizePicker from 'components/atoms/SizePicker';
import mapModifiers, { parseString } from 'utils/functions';

export interface CardProductProps {
  id?: number;
  isFlashSale?: boolean;
  handleAddToCart?: () => void;
  discountPercent?: string;
  colors?: {
    code?: string;
    imgSrc?: string;
    isChecked?: boolean;
  }[];
  sizes?: {
    code?: string;
    name?: string;
    isChecked?: boolean;
  }[];
  name?: string;
  code?: string;
  type?: string;
  typeSlug?: string;
  price?: string;
  oldPrice?: string;
  handleFavorite?: () => void;
  isFavorite?: boolean;
  soldQuantity?: number;
  percent?: number;
  thumbnail?: string[];
  href?: string;
  tag?: {
    label?: string;
    variant?: string;
  }[];
  handleChangeColor?: (code?: string) => void;
  handleChangeSize?: (code?: string) => void;
  handleClick?: () => void;
}

const CardProduct: React.FC<CardProductProps> = ({
  id,
  handleAddToCart,
  handleFavorite,
  isFlashSale,
  discountPercent,
  colors,
  name,
  code,
  type,
  typeSlug,
  price,
  oldPrice,
  soldQuantity,
  thumbnail,
  href,
  isFavorite,
  tag,
  handleChangeColor,
  sizes,
  percent,
  handleChangeSize,
  handleClick
}) => {
  const [idxActive, setIdxActive] = useState<0 | 1>(0);
  const onClick = () => {
    if (handleClick) handleClick();
  };

  return (
    <div className="m-productCard" key={id}>
      <div
        className="m-productCard_thumbnail"
        onMouseEnter={() => setIdxActive(1)}
        onMouseLeave={() => setIdxActive(0)}
      >
        <div className="m-productCard_tag">
          {
            tag?.map((item, index) => (
              <div
                key={`productCard-tag-${index.toString()}`}
                className="m-productCard_tag_item"
                style={{ backgroundColor: item.variant }}
              >
                <Paragraph.Text
                  size={{
                    fontSize: '12',
                    lineHeight: '14'
                  }}
                  sizeMobile={{
                    fontSize: '10',
                    lineHeight: '10'
                  }}
                  modifiers={['white']}
                >
                  {item.label}
                </Paragraph.Text>
              </div>
            ))
          }
        </div>
        <Link href={href} onClick={onClick}>
          <div
            className={mapModifiers('m-productCard_thumbnail-item', thumbnail?.length === 1 && 'scale')}
          >
            <Image ratio="257x386" src={thumbnail?.[idxActive] || thumbnail?.[0]} alt={name} />
          </div>
        </Link>
        {
          isFlashSale && (
            <div className="m-productCard_iconFlashSale">
              <Icon iconName="flash_sale" />
            </div>
          )
        }
        {
          (handleFavorite || isFavorite) && (
            <div onClick={handleFavorite} className="m-productCard_iconFavorite">
              <Icon iconName={isFavorite ? 'love_red' : 'heart_white'} iconSize={isFavorite ? '32' : '30'} />
            </div>
          )
        }
        {
          handleAddToCart && (
            <div
              onClick={handleAddToCart}
              className="m-productCard_addCart"
            >
              <Icon iconName="add_cart" />
            </div>
          )
        }
        {
          discountPercent && (
            <div onClick={handleFavorite} className="m-productCard_discountPercent">
              <Paragraph.Text
                size={{
                  fontSize: '12',
                  lineHeight: '14'
                }}
                sizeMobile={{
                  fontSize: '7',
                  lineHeight: '8'
                }}
                modifiers={['white']}
              >
                Giảm
              </Paragraph.Text>
              <Paragraph.Text
                type="div"
                size={{
                  fontSize: '33',
                  lineHeight: '36'
                }}
                sizeTablet={{
                  fontSize: '29',
                  lineHeight: '29'
                }}
                sizeMobile={{
                  fontSize: '18',
                  lineHeight: '18'
                }}
                modifiers={['white', '500']}
                isInline
              >
                {discountPercent}
                <span>%</span>
              </Paragraph.Text>
            </div>
          )
        }
      </div>
      <div className="m-productCard_content u-mt-14">
        {colors && colors.length > 0 && (
          <div className="m-productCard_listColor d-flex u-ml-negative-5 u-mr-negative-5 flex-wrap">
            {colors.map((item, index) => (
              <div className="u-pl-5 u-pr-5" key={`list-product-color-${index.toString()}`}>
                <ColorPicker
                  type="radio"
                  checked={item.isChecked}
                  imgSrc={item.imgSrc}
                  onChange={() => handleChangeColor && handleChangeColor(item.code)}
                />
              </div>
            ))}
          </div>
        )}

        {sizes && sizes?.length > 0 && (
          <div className="d-flex align-items-center u-mt-md-4 u-ml-negative-2 u-mr-negative-2 u-ml-negative-md-8 u-mr-negative-md-8 flex-wrap">
            {sizes?.map((x, i) => (
              <div className="u-pl-2 u-pr-2 u-pl-md-8 u-pr-md-8 u-mt-8" key={`size-${i.toString()}`}>
                <SizePicker
                  type="radio"
                  checked={x.isChecked}
                  sizePicker={parseString(x.name)}
                  onChange={() => handleChangeSize && handleChangeSize(x.code)}
                />
              </div>
            ))}
          </div>
        )}
        <div className="u-mt-5 u-mt-md-8 m-productCard_name">
          <Link href={href} onClick={onClick}>
            <Paragraph.Text
              size={{
                fontSize: '18',
                lineHeight: '22 '
              }}
              sizeMobile={{
                fontSize: '14',
                lineHeight: '17 '
              }}
            >
              {name}
            </Paragraph.Text>
          </Link>
        </div>
        {code && (
          <div className="u-mt-8 u-mt-md-12">
            <Paragraph.Text
              size={{
                fontSize: '16',
                lineHeight: '21 '
              }}
              sizeMobile={{
                fontSize: '11',
                lineHeight: '21 '
              }}
              modifiers={['raisinBlack05']}
            >
              {code}
            </Paragraph.Text>
          </div>
        )}
        <div className="d-flex justify-content-between align-items-end u-mt-10 u-mt-md-20">
          <Paragraph.Text
            sizeTablet={{
              fontSize: '16',
              lineHeight: '18'
            }}
            size={{
              fontSize: '20',
              lineHeight: '24'
            }}
            modifiers={[oldPrice ? 'spanishCrimson' : 'raisinBlack', '500']}
          >
            {price}
          </Paragraph.Text>
          {oldPrice && (
            <Paragraph.Text
              size={{
                fontSize: '16',
                lineHeight: '19'
              }}
              sizeTablet={{
                fontSize: '13',
                lineHeight: '16'
              }}
              modifiers={['raisinBlack03', 'lineThrough']}
            >
              {oldPrice}
            </Paragraph.Text>
          )}
        </div>
        {
          soldQuantity !== undefined && (
            <>
              {percent !== undefined
                && (
                  <div className="u-mt-14">
                    <div className="m-productCard_progress-track">
                      <div style={{ width: `${percent || 0}%` }} className="m-productCard_progress-range" />
                    </div>
                  </div>
                )}
              <div className="u-mt-12">
                <Paragraph.Text
                  size={{
                    fontSize: '16',
                    lineHeight: '21'
                  }}
                  sizeMobile={{
                    fontSize: '11',
                    lineHeight: '21 '
                  }}
                  modifiers={['raisinBlack05']}
                >
                  {soldQuantity}
                  {' '}
                  đã bán
                </Paragraph.Text>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

CardProduct.defaultProps = {
};

export default CardProduct;
