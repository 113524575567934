import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';

const useAuthCallback = <A extends unknown[]>(
  cb: (...args: A) => void,
  deps: React.DependencyList,
  redirect?: string
) => {
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.auth.profile);
  const linkLogin = useAppSelector((state) => state.systems.static?.login);

  return useCallback((...args: A) => {
    if (!profile) {
      navigate(`/${linkLogin}${redirect ? `?redirect=${redirect}` : ''}`);
    } else {
      cb(...args);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, profile, linkLogin]);
};

export default useAuthCallback;
