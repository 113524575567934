import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './action';
import State from './types';

const initialState: State = {
  local: [],
  auth: [],
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers,
});

export const {
  updateFavoriteLocalAction,
  updateFavoriteAuthAction,
  clearFavoriteLocalAction,
  clearFavoriteAuthAction
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
