import { createAsyncThunk } from '@reduxjs/toolkit';

import { getListShippingAddressService } from 'services/shippingAddress';
import { IShippingAddress } from 'services/shippingAddress/types';
import { getListVoucherCustomerService } from 'services/voucher';
import { IVoucher } from 'services/voucher/types';

// Shipping Address
export const getListShippingAddressAsync = createAsyncThunk<
  IShippingAddress[],
  void,
  { rejectValue: unknown }
>(
  'customers/getShippingAddress',
  async (_, { rejectWithValue }) => {
    try {
      return await getListShippingAddressService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Vouchers
export const getListVoucherCustomerAsync = createAsyncThunk<
  IVoucher[],
  void,
  { rejectValue: unknown }
>(
  'customers/getListVoucher',
  async (_, { rejectWithValue }) => {
    try {
      return await getListVoucherCustomerService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
