import axios, { CancelTokenSource } from 'axios';

import { BranchPayload, IBranch } from './types';

import axiosInstance from 'services/common/instance';

let cancelTokenSourceBranch: CancelTokenSource;

export const getBranchService = async (
  params?: BranchPayload,
): Promise<IBranch[]> => {
  if (cancelTokenSourceBranch) {
    cancelTokenSourceBranch.cancel('Cancelling in cleanup');
  }
  cancelTokenSourceBranch = axios.CancelToken.source();
  const res = await axiosInstance.get('branchs', {
    params,
    cancelToken: cancelTokenSourceBranch.token,
  });
  return res.data.data;
};

export const getBranchShowroomService = async (
  params?: BranchPayload,
): Promise<IBranch[]> => {
  const res = await axiosInstance.get('branchs', {
    params,
  });
  return res.data.data;
};
