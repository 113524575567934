/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProductSku } from 'services/product';
import { ProductSkuItem, ProductSkuPayload } from 'services/product/types';

export const getProductSkuAsync = createAsyncThunk<
  ProductSkuItem[],
  ProductSkuPayload,
  { rejectValue: unknown }
>(
  'cart/getProductSku',
  async (params, { rejectWithValue }) => {
    try {
      const res = await getProductSku(params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
