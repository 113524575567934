import { baseURL, renderValue } from 'utils/functions';

export const getThumbnailProduct = ({
  product,
  colorChecked,
  sliceThumbnailFrom = 0,
  currentSkuCode,
}: {
  product?: IProducts,
  colorChecked?: string;
  sliceThumbnailFrom?: number;
  currentSkuCode?: string;
}): {
  code: string;
  src: string;
  alt: string;
  video?: string;
  currentSkuCode?: string;
}[] => {
  if (!product || !product.colors || !product.colors.length) return [];

  const initColor = product?.colors[0];

  const item = colorChecked ? product.colors.find((e) => e.code === colorChecked) : initColor;

  if (item) {
    const itemDefault = {
      code: product.code,
      src: baseURL(item?.media?.gallery?.length ? item?.media?.gallery[0]?.path : ''),
      video: item?.media?.video,
      alt: product.name,
      currentSkuCode,
    };

    if (!item?.media?.gallery?.length && item?.media?.thumbnail?.path) {
      return [itemDefault];
    }

    const sliceStart = Number(item?.media?.gallery?.length || 0) > 2 ? sliceThumbnailFrom : 0;

    const listNormal = item?.media?.gallery?.slice(sliceStart)?.map((e) => ({
      code: product.code,
      src: baseURL(e.path),
      alt: product.name,
      currentSkuCode,
    })) || [];

    return item?.media?.video ? listNormal.concat(itemDefault) : listNormal;
  }

  return [];
};

export const getPriceProduct = (sku?: ISku) => {
  if (!sku) return undefined;

  const { price, salePrice = 0 } = sku;

  return {
    current: renderValue(salePrice < price ? salePrice : price, true, 'đ')?.toString(),
    sale: salePrice < price ? renderValue(price, true, 'đ')?.toString() : undefined,
  };
};

export const getSizesProduct = (product?: IProducts) => {
  if (!product?.sizes?.length) return [];

  return product?.sizes?.map((e) => ({
    label: e?.name,
    value: e?.code,
  }));
};

export const getColorsProduct = (product?: IProducts) => {
  if (!product?.colors?.length) return [];

  return product?.colors?.map((e) => ({
    imgSrc: baseURL(e?.media?.color?.path),
    value: e?.code,
    name: e?.name
  }));
};

export const getCurrentSkuProduct = (product?: IProducts, size?: string, color?: string) => {
  if (!product?.sku?.length) return undefined;
  const initColor = color || product?.colors?.[0]?.code;
  const initSize = size || product?.sizes?.[0]?.code;

  return product?.sku?.find((e) => e.color?.code === initColor && e.size?.code === initSize);
};
