import React, { useCallback, useRef } from 'react';

import { HeaderButtonIcon, HeaderButtonIconProps } from '../HeaderElements';
import functions from '../functions';

import Link, { LinkProps } from 'components/atoms/Link';
import useWindowScroll from 'hooks/useWindowScroll';
import { IMenuItem } from 'services/menus/types';
import { useAppSelector } from 'store/hooks';
import { baseURL } from 'utils/functions';

interface NavBottomButtonProps extends HeaderButtonIconProps {
  linkProps: LinkProps;
}

export const NavBottomButton: React.FC<NavBottomButtonProps> = ({
  linkProps, ...props
}) => (
  <Link {...linkProps} className="nav-bottom_element">
    <HeaderButtonIcon
      {...props}
    />
  </Link>
);

interface NavBottomProps {
  onOpenSlider?: () => void;
}

const NavBottom: React.FC<NavBottomProps> = ({ onOpenSlider }) => {
  const ref = useRef<HTMLDivElement>(null);
  const menuNavBottom = useAppSelector((state) => state.menus.menuNavBottom);
  const cart = useAppSelector((state) => state.cart);

  const onClick = useCallback((e: IMenuItem) => {
    if (e.cssClass === 'open-mobile-menu' && onOpenSlider) {
      onOpenSlider();
    }
  }, [onOpenSlider]);

  useWindowScroll(() => {
    const btnAddToCart = document.querySelector('#btnAddToCart');
    if (ref?.current) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        ref.current.style.height = '0px';
        ref.current.style.overflowY = 'hidden';
        if (btnAddToCart) {
          btnAddToCart?.classList.remove('show');
          btnAddToCart?.classList.add('hidden');
        }
      } else {
        ref.current.style.overflowY = 'auto';
        ref.current.style.height = '60px';
        if (btnAddToCart) {
          btnAddToCart?.classList.add('show');
          btnAddToCart?.classList.remove('hidden');
        }
      }
    }
  });

  return (
    <nav className="nav-bottom" ref={ref}>
      {menuNavBottom?.items?.map((e, i) => (
        <NavBottomButton
          key={`nav-bottom-${i.toString()}`}
          linkProps={{ href: functions.linkMenu(e), target: e.target }}
          imgSrc={baseURL(e?.iconImage?.path)}
          badgeNumber={e?.cssClass === 'cart' ? cart?.listProduct?.length : undefined}
          onClick={() => onClick(e)}
          imgSrcSize={
            {
              width: '25',
              height: '25'
            }
          }
        />

      ))}
    </nav>
  );
};

export default NavBottom;
