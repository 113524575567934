/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  HeaderButtonIcon
} from './HeaderElements';
import NavBottom from './NavBottom';
import NavSearch from './NavSearch';
import NavSider from './NavSider';
import NavTop from './NavTop';
import Search from './Search';

import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Paragraph from 'components/atoms/Paragraph';
import Container from 'components/organisms/Container';
import useAuthCallback from 'hooks/useAuthCallback';
import { useAppSelector } from 'store/hooks';
import { LOGO_DESKTOP_URL } from 'utils/constants';
import mapModifiers, { baseURL } from 'utils/functions';

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
  const cart = useAppSelector((state) => state.cart);
  const systems = useAppSelector((state) => state.systems);
  const menuHotline = useAppSelector((state) => state.menus.menuHotline);
  const logoDesktop = LOGO_DESKTOP_URL;
  const navigate = useNavigate();

  const [isSiderOpen, setIsSiderOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const onClickAccount = useAuthCallback(() => {
    navigate({
      pathname: `/${systems?.static?.thongTinTaiKhoan}`,
    });
  }, [navigate, systems], systems.static?.thongTinTaiKhoan);

  const onClickFavoried = useAuthCallback(() => {
    navigate({
      pathname: `/${systems?.static?.sanPhamYeuThich}`,
    });
  }, [navigate, systems], systems.static?.sanPhamYeuThich);

  const onClickSearchProduct = useAuthCallback(() => {
    navigate({
      pathname: `/${systems?.static?.theoDoiDonHang}`,
    });
  }, [navigate, systems], systems.static?.theoDoiDonHang);

  const redirect = useCallback((href?: string) => {
    navigate({
      pathname: `/${href}`,
    });
  }, [navigate]);

  const badgeNumber = useMemo(
    () => cart.listProduct.reduce((pre, cur) => pre + cur.quantity, 0),
    [cart]
  );

  return (
    <>
      <header className="t-header">
        <div className="t-header_top t-header_topBackground">
          <Container isHeader>
            <div className="t-header_flexBetween t-header_contact">
              <Link useExternal className="t-header_contactLeft" href={menuHotline?.items?.[0]?.link}>
                <img
                  style={{ width: 10, height: 10 }}
                  src={baseURL(menuHotline?.items?.[0]?.iconImage?.path)}
                  alt={menuHotline?.items?.[0]?.title}
                />
                <Paragraph.Text
                  type="span"
                  size={{ fontSize: '16', lineHeight: '19' }}
                  sizeMobile={{ fontSize: '14', lineHeight: '17' }}
                  modifiers={['white']}
                >

                  {menuHotline?.items?.[0]?.title}
                </Paragraph.Text>
              </Link>
              <div className="t-header_contactRight">
                <div className={mapModifiers('t-header_contactRight_element', 'desktop')}>
                  <button type="button" className="t-header_contactRight_btn" onClick={onClickSearchProduct}>
                    <Icon iconName="location_search_white" iconSize="20" />
                    Tra cứu tình trạng đơn hàng
                  </button>
                </div>
                <div className={mapModifiers('t-header_contactRight_element', 'desktop')}>
                  <button type="button" className="t-header_contactRight_btn" onClick={() => redirect(systems?.static?.showroom)}>
                    <Icon iconName="location_white" iconSize="18" />
                    Hệ thống Showroom
                  </button>
                </div>
                <div className={mapModifiers('t-header_contactRight_element', 'desktop', 'search')}>
                  <Search />
                </div>
                <div className={mapModifiers('t-header_contactRight_element', 'mobileTablet')}>
                  <HeaderButtonIcon
                    icon={{ iconName: 'search_white', iconSize: '18' }}
                    onClick={() => {
                      setIsSearchOpen(true);
                    }}
                  />
                </div>
                <div className={mapModifiers('t-header_contactRight_element', 'tabletDesktop')}>
                  <HeaderButtonIcon
                    icon={{ iconName: 'hamburger_white', iconSize: '20' }}
                    onClick={() => setIsSiderOpen(true)}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="t-header_bottom">
          <Container>
            <div className="t-header_flexBetween t-header_navWrapper">
              <div className="t-header_logo">
                <Link href="/">
                  <Image src={logoDesktop} alt="logo" ratio="184x28" />
                </Link>
              </div>

              {/* Dropdown */}
              <NavTop />

              <div className="t-header_actions">
                <div className={mapModifiers('t-header_actions_element', 'desktop')}>
                  <HeaderButtonIcon icon={{ iconName: 'user_black', iconSize: '20' }} onClick={onClickAccount} />
                </div>
                <div className={mapModifiers('t-header_actions_element', 'desktop')}>
                  <HeaderButtonIcon
                    icon={{ iconName: 'heart_black', iconSize: '20' }}
                    onClick={onClickFavoried}
                  />
                </div>
                <div className={mapModifiers('t-header_actions_element', 'desktop')}>
                  <HeaderButtonIcon
                    icon={{ iconName: 'cart_black', iconSize: '20' }}
                    onClick={() => redirect(systems?.static?.gioHang)}
                    badgeNumber={badgeNumber}
                  />
                </div>
                <div className={mapModifiers('t-header_actions_element', 'mobile')}>
                  <HeaderButtonIcon
                    icon={{ iconName: 'hamburger_black', iconSize: '20' }}
                    onClick={() => setIsSiderOpen(true)}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </header>

      {/* Bottom Navigation */}
      <NavBottom onOpenSlider={() => setIsSiderOpen(true)} />

      {/* Sider */}
      <NavSider isSiderOpen={isSiderOpen} handleCloseSider={() => setIsSiderOpen(false)} />

      {/* Search Nav */}
      <NavSearch open={isSearchOpen} handleClose={() => setIsSearchOpen(false)} />
    </>
  );
};

export default Header;
