import React, { useEffect, useState } from 'react';

import Icon, { IconSize } from 'components/atoms/Icon';
import Paragraph from 'components/atoms/Paragraph';
import mapModifiers from 'utils/functions';

interface StarRateTypes {
  rate?: number;
  variant?: 'star_blue' | 'star_orange';
  iconSize?: IconSize;
  handleVoteRate?: (val: number) => void;
  spacing?: 'md' | 'lg';
}

export const StarList: React.FC<StarRateTypes> = ({
  variant = 'star_blue',
  iconSize = '12',
  rate,
  spacing = 'lg',
  handleVoteRate,
}) => {
  const [value, setValue] = useState(rate);
  const [hover, setHover] = useState(rate || 0);

  const handleSubmit = (val: number) => {
    if (handleVoteRate) {
      handleVoteRate(val);
      setValue(val);
    }
  };

  useEffect(() => {
    setValue(rate);
    setHover(rate || 0);
  }, [rate]);

  return (
    <div className={`u-ml-negative-${spacing === 'lg' ? '5' : '2'} u-mr-negative-${spacing === 'lg' ? '5' : '2'} d-flex ${mapModifiers('o-ratingProduct_starList', handleVoteRate && 'cursor')}`}>
      {
        [...Array(5)].map((_, index) => (
          <div
            onMouseLeave={() => handleVoteRate && setHover(Number(value))}
            onMouseEnter={() => handleVoteRate && setHover(index + 1)}
            onClick={() => handleSubmit(index + 1)}
            className={`u-pl-${spacing === 'lg' ? '5' : '2'} u-pr-${spacing === 'lg' ? '5' : '2'}`}
            key={`star-list-${index.toString()}`}
          >
            <Icon iconName={hover > index ? variant : 'star_out_line'} iconSize={iconSize} />
          </div>
        ))
      }
    </div>
  );
};

interface ProgressProps {
  progress?: number;
}

export const Progress: React.FC<ProgressProps> = ({ progress }) => (
  <div className="o-ratingProduct_progress">
    <div style={{ width: `${progress}%` }} className="o-ratingProduct_progress-range" />
  </div>
);

interface RatingProductProps {
  totalOneStar?: number;
  totalTwoStar?: number;
  totalThreeStar?: number;
  totalFourStar?: number;
  totalFiveStar?: number;
  averageRating?: number;
  total?: number;
}

const RatingProduct: React.FC<RatingProductProps> = ({
  totalFiveStar = 0,
  totalFourStar = 0,
  totalOneStar = 0,
  totalThreeStar = 0,
  totalTwoStar = 0,
  averageRating = 0,
  total = 0,
}) => {
  const listRate = [totalFiveStar, totalFourStar, totalThreeStar, totalTwoStar, totalOneStar];
  // const total = totalFiveStar + totalFourStar + totalOneStar + totalThreeStar + totalTwoStar;
  // const averageRating = ((
  //   (totalFiveStar * 5)
  //   + (totalFourStar * 4)
  //   + totalOneStar
  //   + (totalThreeStar * 3)
  //   + (totalTwoStar * 2))
  //   / (total)).toFixed(1);
  const getPercent = (val: number) => (val / total) * 100;

  return (
    <div className="o-ratingProduct">
      <div className="d-flex align-items-center">
        <Paragraph.Heading
          size={{
            fontSize: '60',
            lineHeight: '60'
          }}
          modifiers={['700', 'vividCerulean']}
          content={averageRating.toString()}
        />
        <div className="u-ml-24">
          <StarList
            iconSize="20"
            rate={Math.floor(Number(averageRating))}
          />
          <div className="u-mt-8">
            <Paragraph.Text
              size={{
                fontSize: '16',
                lineHeight: '21'
              }}
              modifiers={['raisinBlack05']}
            >
              {`${total} nhận xét`}
            </Paragraph.Text>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center` o-ratingProduct_listRate u-mt-24">
        <div className="w-100">
          {
            listRate.map((item, index) => (
              <div
                key={`listRate-${index.toString()}`}
                className="o-ratingProduct_listRate-item u-mb-15 u-pt-5 u-pb-4 d-flex align-items-center"
              >
                <StarList variant="star_blue" rate={5 - index} />
                <div className="u-mr-18 u-ml-18 o-ratingProduct_listRate-progress">
                  <Progress progress={getPercent(item)} />
                </div>
              </div>
            ))
          }
        </div>
        <div>
          {
            listRate.map((item, index) => (
              <div
                key={`listRate-${index.toString()}`}
                className="o-ratingProduct_listRate-item u-mb-15 d-flex align-items-center"
              >
                <div className="o-ratingProduct_listRate-number">
                  <Paragraph.Text
                    size={{
                      fontSize: '16',
                      lineHeight: '21'
                    }}
                    modifiers={['left', 'raisinBlack05']}
                  >
                    {item}
                  </Paragraph.Text>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

RatingProduct.defaultProps = {
};

export default RatingProduct;
