import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import systemsGeneralAsync, { staticAllAsync } from './thunk';
import State from './types';

import {
  IContactInformation,
  IFaceBookID, IFooter,
  IHotlineSites, IMediaUrl,
  ISectionBanner, ISocial, ISystems
} from 'services/systems/types';

const CODE = {
  contactInformation: 'get-contact-information',
  hotlineSites: 'get-hotline-sites',
  footer: 'get-footer',
  sectionBanner: 'get-section-banner',
  social: 'get-icon-social',
  mediaUrl: 'media-url',
  systems: 'systems',
  faceBookID: 'get-facebook-page-id'
};

const builderSystem = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(systemsGeneralAsync.fulfilled, ($state, action) => {
    // contactInformation
    const contactInformation = action.payload.find(
      (item) => item.code === CODE.contactInformation
    )?.data as IContactInformation;

    if (contactInformation) {
      $state.contactInformation = contactInformation;
    }

    // hotlineSites
    const hotlineSites = action.payload.find(
      (item) => item.code === CODE.hotlineSites
    )?.data as IHotlineSites;

    if (hotlineSites) {
      $state.hotlineSites = hotlineSites;
    }

    // footer
    const footer = action.payload.find((item) => item.code === CODE.footer)?.data as IFooter;

    if (footer) {
      $state.footer = footer;
    }

    // sectionBanner
    const sectionBanner = action.payload.find(
      (item) => item.code === CODE.sectionBanner
    )?.data as ISectionBanner;

    if (sectionBanner) {
      $state.sectionBanner = sectionBanner;
    }

    // social
    const social = action.payload.find((item) => item.code === CODE.social)?.data as ISocial;

    if (social) {
      $state.social = social;
    }

    // mediaUrl
    const mediaUrl = action.payload.find((item) => item.code === CODE.mediaUrl)?.data as IMediaUrl;

    if (mediaUrl) {
      $state.mediaUrl = mediaUrl;
    }

    // systems
    const systems = action.payload.find((item) => item.code === CODE.systems)?.data as ISystems;

    if (systems) {
      $state.systems = systems;
    }

    // faceBookID
    const faceBookID = action.payload.find(
      (item) => item.code === CODE.faceBookID
    )?.data as IFaceBookID;

    if (faceBookID) {
      $state.faceBookID = faceBookID;
    }
  });
  builder.addCase(staticAllAsync.fulfilled, ($state, action) => {
    $state.static = action.payload;
  });
};

export default builderSystem;
