import {
  useCallback, useMemo, useRef
} from 'react';

import { toastSingleMode } from 'components/atoms/Toastify';
import { useAsync } from 'hooks/useAsync';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { toggleLikeService } from 'services/product';
import { updateFavoriteAuthAction, updateFavoriteLocalAction } from 'store/favorite';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const useFavorite = (id?: number, initFavorite?: boolean) => {
  const dispatch = useAppDispatch();
  const { local, auth } = useAppSelector((state) => state.favorite);
  const profile = useAppSelector((state) => state.auth.profile);
  const isLogin = useMemo(() => !!profile, [profile]);
  const timeoutId = useRef<NodeJS.Timeout>();

  /** Start Favorite auth */
  const [executeLike] = useAsync(toggleLikeService, {
    onFailed: () => {
      const find = auth.find((x) => x.id === id);
      const flag = find ? find.flag : !!initFavorite;
      if (id) {
        dispatch(updateFavoriteAuthAction({
          id,
          flag
        }));
      }
    }
  });

  const notifyFavorite = useCallback((isLike?: boolean) => {
    if (isLike) {
      toastSingleMode({
        type: 'success',
        message: 'Bạn vừa thêm 1 sản phẩm vào mục yêu thích thành công!',
      });
    }
  }, []);

  const handleFavoriteAuth = useDebounceCallback(() => {
    if (id) {
      const find = auth.find((x) => x.id === id);
      const flag = find ? !find.flag : !initFavorite;
      notifyFavorite(flag);
      dispatch(updateFavoriteAuthAction({
        id,
        flag
      }));
      executeLike({
        id,
        flag,
      });
    }
  }, 300, timeoutId);

  const isFavoriteAuth = useMemo(() => {
    const find = auth.find((x) => x.id === id);
    return find ? find.flag : !!initFavorite;
  }, [auth, id, initFavorite]);
  /** End Favorite auth */

  /** Start Favorite local */

  const handleFavoriteLocal = useCallback(() => {
    if (!id) return;
    const flag = !(local.findIndex((x) => x.id === id) !== -1);
    notifyFavorite(flag);
    dispatch(updateFavoriteLocalAction({
      id,
      flag
    }));
  }, [dispatch, id, local, notifyFavorite]);

  const isFavoriteLocal = useMemo(
    () => local.findIndex((x) => x.id === id) !== -1,
    [id, local]
  );
  /** End Favorite local */

  return ({
    isFavorite: isLogin ? isFavoriteAuth : isFavoriteLocal,
    handle: isLogin ? handleFavoriteAuth : handleFavoriteLocal
  });
};

export default useFavorite;
